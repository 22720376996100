import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { FileUploadArea, FileUploadStatus } from 'components/Utility/FileUploadArea'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { addMinutes, isBefore } from 'date-fns'
import { PAPDIS_UPLOAD_FILE_TYPES } from 'lib/constants'
import { getScreenAppWidth } from 'lib/scaleHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useAddGroupSchemeJobWithFileMutation, useBulkAcknowledgeGroupSchemeJobsMutation, useGetGroupSchemeJobsQuery, useGetSampleFileQuery } from 'store/apiSlice'
import { GroupSchemeJobDataSetFormat, GroupSchemeJobStatus, GroupSchemeJobType, GroupSchemeStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerJobDataId, setShowEmployerAddContributionJobVisible, setSnackbarData } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerContributionListItem } from '../Components/EmployerContributionListItem'
import { Button } from 'components/Utility/Button'
import Collapsible from 'react-native-collapsible'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { BulkSelectToolbar } from 'components/Utility/BulkSelectToolbar'
import { useForm } from 'react-hook-form'
import { difference, union, pull } from 'lodash'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { userCanManageJobsForScheme, userHasRequiredRole } from 'lib/groupSchemeHelpers'
import { GroupSchemeRole } from 'store/dto/group-organization.dto'
import { AppIllustration } from 'components/Utility/AppIllustration'

const isWeb = platformIsWeb()

const JOB_POLLING_INTERVAL_MS = 60000
const JOB_STALE_MINUTES = 60

export const EmployerContributionsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  
  const userCanManageJobs = userCanManageJobsForScheme(currentScheme)

  const { colors: themeColors } = Paper.useAppTheme()

  const [bulkSelectMode, setBulkSelectMode] = useState(false)
  
  const enableBulkSelect = () => {
    setBulkSelectMode(true)
  }

  const cancelBulkSelect = () => {
    setBulkSelectMode(false)
    unselectAllRecords()
  }
  
  const formObj = useForm<{
    selectedJobIds: string[],
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      selectedJobIds: [],
    },
  })

  const { handleSubmit, watch, reset, setValue } = formObj

  const [closeDialogVisible, setCloseDialogVisible] = useState(false)
  const [newCollapsed, setNewCollapsed] = useState(false)
  const hideNew  = () => { setNewCollapsed(true) }
  const showNew = () => { setNewCollapsed(false) }

  const screenWidth = getScreenAppWidth()
  const useHorizontalLayout = isWeb && screenWidth >= 1000

  const { data: contributionSampleFile, isLoading: contributionSampleFileIsLoading, error: contributionSampleFileError } = useGetSampleFileQuery('jarvis_sample_papdis_contribution_upload.csv')

  const [hasPendingJobs, setHasPendingJobs] = useState(false)

  const { data: jobs, isLoading: jobsIsLoading, error: jobsError, isFetching: jobsIsFetching, refetch: refetchJobs } = useGetGroupSchemeJobsQuery({
    groupSchemeId: currentScheme?.id,
    resultAcknowledged: 'false',
    jobType: GroupSchemeJobType.CONTRIBUTION,
  }, { skip: !currentScheme, pollingInterval: hasPendingJobs ? JOB_POLLING_INTERVAL_MS : undefined })

  const [addContributionsJob, { data: addContributionsResult, isLoading: addContributionsJobIsLoading, error: addContributionsJobError }] = useAddGroupSchemeJobWithFileMutation()
  const [acknowledgeMultpleJobs, { isLoading: acknowledgeMultipleIsLoading, error: acknowledgeMultipleError }] = useBulkAcknowledgeGroupSchemeJobsMutation()

  const allCompletedJobIds = jobs ? jobs.filter(job => {
    return job.status === GroupSchemeJobStatus.COMPLETED
  }).map(job => {
    return job.id
  }) : [] 

  const selectedJobIds = watch('selectedJobIds')
  
  const anySelected = !!selectedJobIds.length

  const handleAcknowledgeMultiple = async () => {
    acknowledgeMultpleJobs({
      groupSchemeId: currentScheme?.id,
      jobIds: selectedJobIds,
    })
    setValue('selectedJobIds', [], { shouldValidate: true, shouldDirty: false })
    setCloseDialogVisible(false)
    setBulkSelectMode(false)
  }

  const unselectRecord = (id: string) => {
    setValue('selectedJobIds', pull(selectedJobIds, id))
  }
  
  const selectRecord = (id: string) => {
    setValue('selectedJobIds', union(selectedJobIds, [id]))
  }

  const unselectAllRecords = () => {
    setValue('selectedJobIds', [])
  }

  const selectAllRecords = () => {
    setValue('selectedJobIds', allCompletedJobIds)
  }

  const [refreshing, setRefreshing] = useState(false)

  //Update hasPendingJobs on jobs change
  useEffect(() => {
    const now = new Date()
    if (jobs) {
      const anyRecentPendingJob = jobs ? jobs.find(job => {
        return [GroupSchemeJobStatus.PENDING, GroupSchemeJobStatus.PROCESSING].includes(job.status) && isBefore(now, addMinutes(new Date(job.createdAt), JOB_STALE_MINUTES))
      }) : undefined
      if (hasPendingJobs !== !!anyRecentPendingJob) {
        setHasPendingJobs(!!anyRecentPendingJob)
      }
    }
  }, [jobs])
  
  const [contributionsFile, setContributionsFile] = useState<File>(undefined)
  const [contributionsFileUploadStatus, setContributionsFileUploadStatus] = useState<FileUploadStatus>(FileUploadStatus.IDLE)

  const handleContributionsFileChange = (file: File) => {
    setContributionsFile(file)
    setContributionsFileUploadStatus(FileUploadStatus.IDLE)
  }

  const handleContributionsFileUpload = () => {
    setContributionsFileUploadStatus(FileUploadStatus.IDLE)
    const formData = new FormData()
    formData.append('file', contributionsFile)
    formData.append('jobType', GroupSchemeJobType.CONTRIBUTION)
    formData.append('dataSetFormat', GroupSchemeJobDataSetFormat.PAPDIS)
    addContributionsJob({
      groupSchemeId: currentScheme?.id,
      formData,
    })
  }

  //Sync contributions upload status
  useEffect(() => {
    setContributionsFileUploadStatus(
      addContributionsResult ? FileUploadStatus.COMPLETE
      : addContributionsJobError ? FileUploadStatus.ERROR_UPLOAD
      : addContributionsJobIsLoading ? FileUploadStatus.UPLOADING
      : FileUploadStatus.IDLE
    )
  }, [addContributionsResult, addContributionsJobIsLoading, addContributionsJobError])

  //Show Snackbar when uploaded/error - contributions
  useEffect(() => {
    if (addContributionsResult || addContributionsJobError) {
      dispatch(setSnackbarData({
        message: addContributionsJobError ? `File Upload Failed` : `File Uploaded Successfully`,
        subMessage: addContributionsJobError ? `Please check that the file exists and is a valid PAPIS CSV file` : `We'll send you a message when it has been processed`,
        iconName: addContributionsJobError ? 'file-alert-outline' : 'file-check-outline',
        isError: !!addContributionsJobError,
        duration: 5000,
      }))
    }
  }, [addContributionsResult, addContributionsJobError])

  const dispatch = useAppDispatch()

  const handleShowJobDetails = (id: string) => {
    dispatch(setCurrentEmployerJobDataId(id))
  }

  const jobsList = () => {
    let completed = []
    let completedWithErrors = []
    let rejected = []
    let investigating = []
    let processing = []
    let pending = []
    if (jobs) {
      jobs.map((job, id) => {
        if (job.status === GroupSchemeJobStatus.COMPLETED) {
          completed.push(job)
        } else if (job.status === GroupSchemeJobStatus.COMPLETED_WITH_ERRORS) {
          completedWithErrors.push(job)
        } else if (job.status === GroupSchemeJobStatus.REJECTED) {
          rejected.push(job)
        } else if (job.status === GroupSchemeJobStatus.INVESTIGATING) {
          investigating .push(job)
        } else if (job.status === GroupSchemeJobStatus.PROCESSING) {
          processing.push(job)
        } else {
          pending.push(job)
        } 
      })

      const headerTools = completed.length && userCanManageJobs ? <BulkSelectToolbar
        enabled={bulkSelectMode}
        anySelected={anySelected}
        enableFunction={enableBulkSelect}
        cancelFunction={cancelBulkSelect}
        selectAllFunction={selectAllRecords}
        unselectAllFunction={unselectAllRecords}
        bulkActionFunction={() => setCloseDialogVisible(true)}
        bulkActionTitle={'Archive Selected'}
        bulkActionIconName={'archive-check-outline'}
      /> : undefined

      return [
        { title: 'Pending', data: pending },
        { title: 'Processing', data: processing },
        { title: 'Investigating', data: investigating  },
        { title: 'Rejected', data: rejected },
        { title: 'Completed With Errors', data: completedWithErrors },
        { title: 'Completed', data: completed, headerTools },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    const { id } = item
    const selected = selectedJobIds.includes(id)
    return (
      <EmployerContributionListItem
        job={item}
        bulkSelectMode={bulkSelectMode}
        selected={selected}
        onPressFunction={bulkSelectMode
          ? selected
            ? () => unselectRecord(item?.id)
            : () => selectRecord(item?.id)
          : () => handleShowJobDetails(item?.id)}
      />
    )
  }

  const isLoading = jobsIsLoading || contributionSampleFileIsLoading
  const error: any = jobsError

  const onRefresh = () => {
    setRefreshing(true)
    refetchJobs()
    setRefreshing(false)
  }

  const handleAddContributionJob = () => {
    dispatch(setShowEmployerAddContributionJobVisible(true))
  }

  const isOnboarding = currentScheme.status === GroupSchemeStatus.ONBOARDING

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Checking contribution jobs...')} />
        : error ?  <ErrorScreen errorTryAgain={refetchJobs} error={error?.data} />
        : isOnboarding
        ? <View style={ Flex.column.start } >
            <AppIllustration filename={'clock.png'} style={{
              width: '100%',
              height: Sizing.x200,
              resizeMode: 'contain',
              alignSelf: 'center'
            }}/>
            <Subheading>{`Scheme is currently in Onboarding Mode.`}</Subheading>
            <Paragraph>{`You'll be able to submit contributions when your scheme is launched to your members.`}</Paragraph>
          </View>
        : 
        <View style={ Flex.column.start } >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
            >
              {
                userCanManageJobs ?
                <>
                  {
                    isWeb
                      ? <>
                          <View style={{
                            paddingHorizontal: Sizing.x30,
                            paddingBottom: Sizing.x10,
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                          }}>
                            <Subheading style={Typography.defined.sectionListHeader}>{'New Contributions'}</Subheading>                  
                            <Collapsible collapsed={newCollapsed}>
                              <View style={{
                                flexDirection: useHorizontalLayout ? 'row' : 'column',
                                width: useHorizontalLayout ? '100%' : undefined,
                                justifyContent: useHorizontalLayout ? 'space-between' : 'flex-start',
                              }}>
                                <View style={{
                                  ...Flex.column.start,
                                  width: useHorizontalLayout ? '50%' : '100%',
                                  height: useHorizontalLayout ? '100%' : undefined,
                                  paddingHorizontal: Sizing.x10,
                                  paddingTop: Sizing.x10,
                                }}>
                                  <View style={{ flex: 1, paddingTop: Sizing.x5 }}>
                                    <Subheading>
                                      {'Upload PAPDIS files to submit member contributions.'}
                                    </Subheading>
                                    <Paragraph>
                                      {'Select a single PAPDIS CSV, optionally zipped for quicker upload.'}
                                    </Paragraph>
                                    <ExternalLinkButton
                                      url={contributionSampleFile?.downloadUrl}
                                      downloadAsFile={true}
                                      downloadFilename={contributionSampleFile?.id || 'sample'}
                                      labelStyle={{
                                        textDecorationLine: 'none',
                                        textTransform: 'none',
                                      }}
                                      iconName={'file-eye-outline'}
                                      color={Colors.brand.purple1}
                                      iconColor={Colors.brand.purple1}
                                    >
                                      {'Download Sample File'}
                                    </ExternalLinkButton>
                                    <Paragraph style={{
                                      textAlign: 'left'
                                    }}>
                                      {'Data Handling:'}
                                    </Paragraph>
                                    <BulletItem style={{ textAlign: 'left' }}>{'Contribution data will be processed calculating the required total payment.'}</BulletItem>
                                    <BulletItem style={{ textAlign: 'left' }}>{'Records for individuals not yet enrolled (based on National Insurance Number) will be rejected.'}</BulletItem>
                                    <BulletItem style={{ textAlign: 'left' }}>{'Opted-out members (or those within their Auto Enrolment Opt Out Window) will be rejected.'}</BulletItem>
                                    <Button
                                      icon={'table-edit'}
                                      color={Colors.brand.purple1}
                                      labelStyle={{
                                        color: Colors.brand.purple1,
                                      }}
                                      mode={'text'}
                                      onPress={handleAddContributionJob}
                                    >
                                      {'Submit Contributions Manually'}
                                    </Button>
                                  </View>
                                </View>
                                <View style={{
                                  ...Flex.column.center,
                                  width: useHorizontalLayout ? '50%' : '100%',
                                  height: useHorizontalLayout ? '100%' : undefined,
                                  paddingHorizontal: Sizing.x10,
                                }}>
                                  <FileUploadArea
                                    title={'Payroll Contributions'}
                                    dragDropMessage={'Drag and drop a PAPDIS contribution file'}
                                    handleChange={handleContributionsFileChange}
                                    handleRemove={() => handleContributionsFileChange(undefined)}
                                    types={PAPDIS_UPLOAD_FILE_TYPES}
                                    multiple={false}
                                    hoverTitle={'Release to add file'}
                                    fileOrFiles={contributionsFile}
                                    submitHandler={handleContributionsFileUpload}
                                    fileUploadStatus={contributionsFileUploadStatus}
                                    color={Colors.brand.red3}
                                  />
                                </View>
                              </View>
                            </Collapsible>
                            <Button
                              mode='text'
                              color={themeColors.accent}
                              icon={newCollapsed ? 'chevron-down' : 'chevron-up'}
                              onPress={newCollapsed ? showNew : hideNew}
                            >
                              {newCollapsed ? 'Make Contributions...' : 'Hide'}
                            </Button>
                          </View>
                          <ContentDivider />
                        </>
                      : <Subheading>{`To upload contributions, please visit your Jarvis Employer Portal in a browser.`}</Subheading>
                  }
                </>
                : <></>
              }
            <OptionalSectionList
              sections={jobsList()}
              renderItem={renderItem}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`No current contribution jobs`}
            />
          </ScrollView>
          <ConfirmationDialog
            visible={closeDialogVisible}
            title={'Are you sure?'}
            content={`This action cannot be undone!\n\nPlease confirm that you want to archive ${selectedJobIds.length} jobs${selectedJobIds.length > 1 ? 's' : ''}.`}
            onCancel={() => setCloseDialogVisible(false)}
            onConfirm={handleAcknowledgeMultiple}
            confirmLabel={`Mark ${selectedJobIds.length} Exception${selectedJobIds.length > 1 ? 's' : ''} as Actioned`}
            cancelLabel={'Go Back'}
          />
        </View>
      }
    </MainAppScreen>
  )
}
