import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { envVariables } from 'environment'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { createBranchLink } from 'lib/linkHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { isEmpty, pick } from 'lodash'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Clipboard, Image, View } from 'react-native'
import { useGetAffiliateQuery, useRefreshAffiliateApiKeyMutation, useRevokeAffiliateApiKeyMutation, useUpdateAffiliateMutation } from 'store/apiSlice'
import { UpdateAffiliateDto } from 'store/dto/affiliate.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminAffiliateDataId, setCurrentAdminAffiliateDataId, setSnackbarData } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export enum NewAffiliateBlockType {
  BLOCKED = 'Blocked By Default',
  NOTBLOCKED = 'Unblocked By Default',
}

export const AdminEditAffiliatesModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const currentAffiliate = useAppSelector(currentAdminAffiliateDataId)

  
  const currentAffiliateId = useAppSelector(currentAdminAffiliateDataId)

  const { data: affiliate, isLoading: affiliateIsLoading, error: affiliateError } = useGetAffiliateQuery(currentAffiliateId, { skip: !currentAffiliateId } )
  const [updateAffiliate, { data: updatedAffiliate, isLoading: affiliateUpdateIsLoading, error: affiliateUpdateError }] = useUpdateAffiliateMutation()
  const [refreshAffiliateApiKey, { isLoading: affiliateRefreshApiKeyIsLoading, error: affiliateRefreshApiKeyError }] = useRefreshAffiliateApiKeyMutation()
  const [revokeAffiliateApiKey, { isLoading: affiliateRevokeApiKeyIsLoading, error: affiliateRevokeApiKeyError }] = useRevokeAffiliateApiKeyMutation()

  const hasApiKey = !!affiliate?.apiKey

  const isWeb = platformIsWeb()

  const { appUrl } = envVariables

  const copyToClipboard = async (text) => {
    await Clipboard.setString(text)
    dispatch(setSnackbarData({
      message: `Copied: "${text}"`,
      iconName: 'content-copy',
    }))
  }

  const formObj = useForm<{
    name: string,
    blockType: NewAffiliateBlockType
    message: string
    siteUrl: string
    logo: string
    isInternal: boolean
    apiDisabled: boolean
    reportingEmail: string
    affiliatePlatformChargeRatio: string
    affiliateGroupPensionChargeRatio: string
    branchQuickLinkPath: string
    customEmailTexts: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, register, setValue, setError, reset, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger('logo')
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')
  const isInternal = watch('isInternal')

  useEffect(() => {
    if (affiliate) {
      const blockType = affiliate?.userIsBlocked ? NewAffiliateBlockType.BLOCKED : NewAffiliateBlockType.NOTBLOCKED
      reset({
        name: affiliate?.name,
        siteUrl: affiliate?.siteUrl,
        blockType: affiliate?.userIsBlocked ? NewAffiliateBlockType.BLOCKED : NewAffiliateBlockType.NOTBLOCKED,
        message: affiliate?.message,
        logo: affiliate?.logo,
        isInternal: affiliate?.isInternal,
        apiDisabled: affiliate?.apiDisabled,
        reportingEmail: affiliate?.reportingEmail,
        affiliatePlatformChargeRatio: affiliate?.affiliatePlatformChargeRatio ? affiliate.affiliatePlatformChargeRatio.toString() : '',
        affiliateGroupPensionChargeRatio: affiliate?.affiliateGroupPensionChargeRatio ? affiliate.affiliateGroupPensionChargeRatio.toString() : '',
        branchQuickLinkPath: affiliate?.branchQuickLinkPath,
        customEmailTexts: affiliate?.customEmailTexts ? affiliate?.customEmailTexts.join(';') : '',
      })
    }
  }, [affiliate])

  //Form refs for focussing
  const nameRef = useRef(null)
  const siteUrlRef = useRef(null)
  const messageRef = useRef(null)
  const affiliatePlatformChargeRatioRef = useRef(null)
  const affiliateGroupPensionChargeRatioRef = useRef(null)
  const reportingEmailRef = useRef(null)
  const branchQuickLinkPathRef = useRef(null)
  const customEmailTextsRef = useRef(null)

  const onSubmit = async attributes => {
    const { customEmailTexts, } = attributes
    const customEmailTextsArray = isEmpty(customEmailTexts) ? undefined : customEmailTexts.split(';')
    let data: UpdateAffiliateDto = {
      ...pick(attributes, [
        'name',
        'logo',
        'apiDisabled',
        'branchQuickLinkPath',
        // 'isInternal', //Don't allow changing this for now
      ]),
      customEmailTexts: customEmailTextsArray,
      message: isInternal ? undefined : attributes.message,
      siteUrl: isInternal ? undefined : attributes.siteUrl,
      reportingEmail: isInternal || !attributes.reportingEmail || attributes.reportingEmail === '' ? undefined : attributes.reportingEmail,
      affiliatePlatformChargeRatio: isInternal ? 0 : parseFloat(attributes.affiliatePlatformChargeRatio),
      affiliateGroupPensionChargeRatio: isInternal ? 0 : parseFloat(attributes.affiliateGroupPensionChargeRatio),
      userIsBlocked:
        attributes.blockType === NewAffiliateBlockType.BLOCKED
          ? true
          : attributes.blockType === NewAffiliateBlockType.NOTBLOCKED
            ? false
            : undefined,
    }

    await updateAffiliate({ id: affiliate.id, ...data })
  }

  useEffect(() => {
    if (updatedAffiliate) {
      close()
    }
  }, [updatedAffiliate])

  const close = () => {
    dispatch(setCurrentAdminAffiliateDataId(undefined))
  }

  const handleRefreshApiKey = () => {
    refreshAffiliateApiKey(affiliate?.id)
  }

  const handleRevokeApiKey = () => {
    revokeAffiliateApiKey(affiliate?.id)
  }

  const isLoading = affiliateIsLoading ||  affiliateUpdateIsLoading || affiliateRefreshApiKeyIsLoading || affiliateRevokeApiKeyIsLoading
  const error: any = affiliateError || affiliateUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const name = affiliate?.name
  const affiliateGenericLinkUrl = createBranchLink('affiliate', {
    affiliateCode: affiliate?.code
  })
  const affiliateQuickLinkUrl = createBranchLink(affiliate?.branchQuickLinkPath)

  const isNumeric = (value: string) => {
    return isNaN(parseFloat(value)) ? 'Must be a number': true
  }

  const isInRange = (value: string) => {
    const numValue = parseFloat(value)
    const result = numValue <= 1 && numValue >= 0
    return result ? true : 'Must be between 0 and 1'
  }

  const apiDisabledOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Yes',
    },
    {
      value: true,
      label: 'No',
    },
  ]

  return (
    <ModalEditScreen
      formTitle={'Edit Affiliate Details'}
      onDismiss={() => dispatch(setCurrentAdminAffiliateDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this affiliate?'}
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={affiliateUpdateIsLoading ? ['Saving affiliate...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: affiliate?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: name,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Linking Information`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Affiliate Code`,
            value: affiliate?.code,
            copyableValue: true,
          },
          {
            label: `Generic Link`,
            value: affiliateGenericLinkUrl,
            copyableValue: true,
          },
          {
            label: `Branch Quick Link`,
            value: affiliateQuickLinkUrl,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
    />
    <ContentDivider />
    <Subheading style={{ color: themeColors.primary }}>{`API Connectivity`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `API Key`,
            value: affiliate?.apiKey || 'n/a',
            copyableValue: affiliate?.apiKey,
          },
        ]}
        noContentDivider={true}
    />
      <Button
        onPress={handleRefreshApiKey}
        mode={'text'}
        confirmation={{
          requiresConfirmation: true,
          dialogTitle: 'Are you sure?',
          dialogContent: affiliate?.apiKey
            ? 'Refreshing the API Key will revoke the existing key and the affiliate will need to update their systems to use the new value.'
            : 'Generating an API Key will provide access to the Affiliate API once the key value is shared with them.'
        }}
      >
        {affiliate?.apiKey ? 'Refresh API Key' : 'Generate API Key'}
      </Button>
      {
        affiliate?.apiKey
          ? <Button
              onPress={handleRevokeApiKey}
              mode={'text'}
              confirmation={{
                requiresConfirmation: true,
                dialogTitle: 'Are you sure?',
                dialogContent: 'Revoking the API Key will revoke the existing key and the affiliate will no longer be able to access the Affiliate API.'
              }}
            >
            {'Revoke API Key'}
          </Button>
          : <></>
      }
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      {
        hasApiKey ? 
          <>
            <Paragraph>{'API Access Enabled?'}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'apiDisabled'}
              formObj={formObj}
              options={apiDisabledOptions}
              required={hasApiKey}
            />
          </>
          : <></>
      }
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => branchQuickLinkPathRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }}/>
      <ManagedTextInput
        ref={branchQuickLinkPathRef}
        name={'branchQuickLinkPath'}
        formObj={formObj}
        label={'Branch Quick Link Path'}
        placeholder={'Branch.io quick link path/alias'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <ManagedAutoCompleteInput
        name={'blockType'}
        formObj={formObj}
        label={'New User Initial Status'}
        selectOnlyMode={true}
        required={true}
        dataSet={enumToAutocompleteOptions(NewAffiliateBlockType)}
      />
      { isInternal ? <></> :
        <>
          <ManagedTextInput
            ref={affiliatePlatformChargeRatioRef}
            name={'affiliatePlatformChargeRatio'}
            formObj={formObj}
            label={'Platform Charge Ratio'}
            blurOnSubmit={false}
            returnKeyType={'done'}
            keyboardType={'numeric'}
            submitHandler={() => affiliateGroupPensionChargeRatioRef.current?.focus()}
            rules={{
              required: true,
              validate: {
                isNumeric,
                isInRange,
              }
            }}
          />
          <ManagedTextInput
            ref={affiliateGroupPensionChargeRatioRef}
            name={'affiliateGroupPensionChargeRatio'}
            formObj={formObj}
            label={'Group Scheme Charge Ratio'}
            blurOnSubmit={false}
            returnKeyType={'done'}
            keyboardType={'numeric'}
            submitHandler={() => reportingEmailRef.current?.focus()}
            rules={{
              required: true,
              validate: {
                isNumeric,
                isInRange,
              }
            }}
          />
          <ManagedTextInput
            ref={reportingEmailRef}
            name={'reportingEmail'}
            formObj={formObj}
            label={'Reporting Email Address'}
            placeholder={'Email to send reports'}
            autoCapitalize={'none'}
            returnKeyType={'next'}
            keyboardType={'email-address'}
            submitHandler={() => messageRef.current?.focus()}
            blurOnSubmit={false}
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email address"
              },
              required: false,
              minLength: 2,
              maxLength: 40,
            }} />
        <ManagedTextInput
          ref={messageRef}
          name={'message'}
          formObj={formObj}
          label={'Custom Message'}
          placeholder={'For affiliate landing screen'}
          returnKeyType={'next'}
          submitHandler={() => siteUrlRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 10,
            maxLength: 100,
          }}/>
        <ManagedTextInput
          ref={customEmailTextsRef}
          name={'customEmailTexts'}
          formObj={formObj}
          label={'Custom Email Texts'}
          placeholder={'Separate multiple paragraphs by a semicolon'}
          returnKeyType={'next'}
          submitHandler={() => siteUrlRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 10,
            maxLength: 500,
          }}/>
        <ManagedTextInput
          ref={siteUrlRef}
          name={'siteUrl'}
          formObj={formObj}
          label={'Website URL'}
          placeholder={'Link to main affiliate website'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoCapitalize={'none'}
          keyboardType={'url'}
          rules={{
            pattern: {
              value: /^(http|https):\/\/[^ "]+$/,
              message: "Invalid URL (include http/https)"
            },
            required: false,
          }}/>
        </>
      }
    </ModalEditScreen>
  )
}
