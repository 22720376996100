import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetAssetsQuery, useGetGroupPortfolioQuery, useUpdateGroupPortfolioMutation } from 'store/apiSlice'
import { AssetAllocationDto } from 'store/dto/account.dto'
import { AssetDto } from 'store/dto/asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupPortfolioDataId, setCurrentAdminGroupPortfolioDataId } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

type AllocationWithAsset = {
  allocation: AssetAllocationDto
  asset: AssetDto
}

export const AdminEditGroupPortfoliosModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const currentGroupPortfolio = useAppSelector(currentAdminGroupPortfolioDataId)

  const currentGroupPortfolioId = useAppSelector(currentAdminGroupPortfolioDataId)

  const { data: groupPortfolio, isLoading: groupPortfolioIsLoading, error: groupPortfolioError } = useGetGroupPortfolioQuery(currentGroupPortfolioId, { skip: !currentGroupPortfolioId } )
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetAssetsQuery()
  const [updateGroupPortfolio, { data: updatedGroupPortfolio, isLoading: groupPortfolioUpdateIsLoading, error: groupPortfolioUpdateError }] = useUpdateGroupPortfolioMutation()

  const { id, secclModelId, annualFeePercentage, assetAllocation, cashAllocation } = groupPortfolio || {}

  const allocationsWithAssets: AllocationWithAsset[] = assets && assetAllocation ? assetAllocation.map(allocation => {
    const asset = assets.find(asset => {
      return asset.id === allocation.assetId
    })
    return {
      allocation,
      asset,
    }
  }) : []

  const allocationTableData: UnborderedTableRow[] = allocationsWithAssets.map(allocationWithAsset => {
    return {
      label: allocationWithAsset?.asset
        ? `${allocationWithAsset?.asset?.shortName} (${allocationWithAsset?.asset?.isin} / ${allocationWithAsset?.asset?.yahooSymbol})`
        : 'Unknown',
      value: formatPercentageAmount(allocationWithAsset?.allocation.proportion * 100, 2) || '',
    }
  })
  allocationTableData.push({
    label: `Cash (Reserved for Fees)`,
    value: formatPercentageAmount(cashAllocation * 100, 2),
  })
  allocationTableData.push({
    label: `Total`,
    value: formatPercentageAmount(100),
    isTotal: true
  })

  const formObj = useForm<{
    name: string,
    description: string,
    investmentObjectives: string,
    manager: string,
    allowedAsDefaultInvestment: boolean,
    riskRating: number,
    riskRatingScaleMax: number,
    logo: string,
    priority: number,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, register, setValue, reset, setError, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger('logo')
  }

  const handleClearImage = () => {
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')
  const riskRatingScaleMax = watch('riskRatingScaleMax')

  useEffect(() => {
    if (groupPortfolio) {
      reset({
        name: groupPortfolio?.name,
        description: groupPortfolio?.description,
        investmentObjectives: groupPortfolio?.investmentObjectives,
        manager: groupPortfolio?.manager,
        allowedAsDefaultInvestment: groupPortfolio?.allowedAsDefaultInvestment,
        riskRating: groupPortfolio?.riskRating,
        riskRatingScaleMax: groupPortfolio?.riskRatingScaleMax,
        logo: groupPortfolio?.logo,
        priority: groupPortfolio?.priority,
      })
    }
  }, [groupPortfolio])

  //Form refs for focussing
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const investmentObjectivesRef = useRef(null)
  const priorityRef = useRef(null)
  const managerRef = useRef(null)
  const riskRatingRef = useRef(null)
  const riskRatingScaleMaxRef = useRef(null)

  const onSubmit = async attributes => {
      //TODO - PA-1720 - build UI to manage allocations
    await updateGroupPortfolio({
      id: groupPortfolio.id,
      ...attributes,
    })
  }

  useEffect(() => {
    if (updatedGroupPortfolio) {
      close()
    }
  }, [updatedGroupPortfolio])

  const close = () => {
    dispatch(setCurrentAdminGroupPortfolioDataId(undefined))
  }

  const isLoading = groupPortfolioIsLoading ||  groupPortfolioUpdateIsLoading || assetsIsLoading
  const error: any = groupPortfolioError || groupPortfolioUpdateError || assetsError

  const { colors: themeColors } = Paper.useAppTheme()
  
  const isNumeric = (value: string) => {
    return isNaN(parseFloat(value)) ? 'Must be a number': true
  }

  const isInRange = (value: string) => {
    const numValue = parseFloat(value)
    const result = numValue <= 1 && numValue >= 0
    return result ? true : 'Must be between 0 and 1'
  }

  const allowedAsDefaultInvestmentOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Available as default',
    },
    {
      value: false,
      label: `Not Available as default`,
    },
  ]

  return (
    <ModalEditScreen
      formTitle='Edit Group Portfolio Details'
      onDismiss={() => dispatch(setCurrentAdminGroupPortfolioDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this group portfolio?'}
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={groupPortfolioUpdateIsLoading ? ['Saving group portfolio...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Identification'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: id,
            copyableValue: true,
          },
          {
            label: `Seccl Model Id`,
            value: secclModelId,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Asset Allocation'}</Subheading>
      <UnborderedTable
        data={allocationTableData}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Annual Fees'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Calculated Annual Fee Percentage`,
            value: formatPercentageAmount(annualFeePercentage * 100, 2),
          },
        ]}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => descriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 30,
      }}/>
      <ManagedTextInput
        ref={descriptionRef}
        name={'description'}
        formObj={formObj}
        label={'Description'}
        placeholder={'Description for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => investmentObjectivesRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 200,
        }}/>
      <ManagedTextInput
        ref={investmentObjectivesRef}
        name={'investmentObjectives'}
        formObj={formObj}
        label={'Investment Objectives'}
        placeholder={'Investment Objectives for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => priorityRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 300,
        }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Subheading>{'Available as default for Group Schemes?'}</Subheading>
      <ManagedSimpleChoiceInput
        name={'allowedAsDefaultInvestment'}
        formObj={formObj}
        options={allowedAsDefaultInvestmentOptions}
        required={true}
      />
      <Paragraph>{'Priority (for display order)'}</Paragraph>
      <ManagedIntegerInput
        ref={priorityRef}
        name={'priority'}
        formObj={formObj}
        label={'Priority'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => managerRef.current?.focus()}
        required={true}
        minValue={1}
        maxValue={1000}
      />
      <ManagedTextInput
        ref={managerRef}
        name={'manager'}
        formObj={formObj}
        label={'Portfolio Manager'}
        placeholder={'Name of company managing the portfolio'}
        returnKeyType={'done'}
        blurOnSubmit={true}
        submitHandler={() => riskRatingRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 50,
      }}/>
      <Paragraph>{'Risk Rating'}</Paragraph>
      <ManagedIntegerInput
        ref={riskRatingRef}
        name={'riskRating'}
        formObj={formObj}
        label={'Risk Rating'}
        submitHandler={() => riskRatingScaleMaxRef.current?.focus()}
        returnKeyType={'next'}
        blurOnSubmit={false}
        required={true}
        minValue={1}
        maxValue={riskRatingScaleMax}
      />
      <Paragraph>{'Risk Rating Out Of'}</Paragraph>
      <ManagedIntegerInput
        ref={riskRatingScaleMaxRef}
        name={'riskRatingScaleMax'}
        formObj={formObj}
        label={'Risk Rating Out Of'}
        returnKeyType={'done'}
        blurOnSubmit={true}
        required={true}
        minValue={5}
        maxValue={10}
      />
    </ModalEditScreen>
  )
}


