import { Headline, Paragraph, Subheading } from "components/Typography"
import { scaleNormalizer } from "lib/scaleHelpers"
import React, { useState } from 'react'
import { Image, StyleSheet, View } from "react-native"
import { ClientClientSegmentDto } from "store/dto/client.dto"
import { GroupPortfolioDto } from "store/dto/group-portfolio.dto"
import { Colors, Sizing } from "styles"
import { ChargeBreakdown } from "./ChargeBreakdown"
import { ChargeCalculatorSlider } from "./ChargeCalculatorSlider"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { RiskRating } from "./RiskRating"
import { envVariables } from "environment"
import { useGetAssetsQuery } from "store/apiSlice"
import { AssetAllocationDto } from "store/dto/account.dto"
import { AssetDto } from "store/dto/asset.dto"
import { floor, maxBy, min, minBy, range, round, sumBy } from 'lodash'
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import { UnborderedTable, UnborderedTableRow } from "./UnborderedTable"
import { Text } from "components/Typography/Text"
import { formatPercentageAmount } from "lib/generalHelpers"
import { Portal } from "react-native-paper"
import { CardModal } from "components/Layout/CardModal"
import { layoutStyles } from "styles/common"
import { ScrollView } from "react-native-gesture-handler"
import { InvestmentAssetModalContent } from "./InvestmentAssetModalContent"
import { FooterButton } from "./FooterButton"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from "lib/platformHelpers"

const isWeb = platformIsWeb()

type GroupPortfolioModalContentProps = {
  accountDescriptor: string
  groupPortfolio: GroupPortfolioDto
  segment: ClientClientSegmentDto
}

type AllocationWithAsset = {
  allocation: AssetAllocationDto
  asset: AssetDto
}

export const GroupPortfolioModalContent = (props: GroupPortfolioModalContentProps) => {
  const { accountDescriptor, groupPortfolio, segment } = props
  const {
    name,
    description,
    investmentObjectives,
    manager,
    annualFeePercentage,
    riskRating,
    riskRatingScaleMax,
    logo,
    assetAllocation,
    cashAllocation,
  } = groupPortfolio || {}

  const [showAsset, setShowAsset] = useState(undefined)
  
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetAssetsQuery()

  const allocationsWithAssets: AllocationWithAsset[] = assets && assetAllocation ? assetAllocation.map(allocation => {
    const asset = assets.find(asset => {
      return asset.id === allocation.assetId
    })
    return {
      allocation,
      asset,
    }
  }) : []

  const { genericPensionIllustrationUrl } = envVariables

  const allocationTableData: UnborderedTableRow[] = allocationsWithAssets.map(allocationWithAsset => {
    return {
      label: <Text style={{ textDecorationLine: 'underline', textAlign: 'left' }}>{allocationWithAsset?.asset?.shortName || 'Unknown'}</Text>,
      value: formatPercentageAmount(allocationWithAsset?.allocation.proportion * 100, 2) || '',
      linkFunction: () => setShowAsset(allocationWithAsset?.asset),
    }
  })
  allocationTableData.push({
    label: `Cash (Reserved for Fees)`,
    value: formatPercentageAmount(cashAllocation * 100, 2),
  })
  allocationTableData.push({
    label: `Total`,
    value: formatPercentageAmount(100),
    isTotal: true
  })

  return (
    <>
      <Headline style={localStyles.title}>{name}</Headline>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{description}</Subheading>
      <View style={{
        alignSelf: 'center',
      }}>
        <Image source={{ uri: logo }} style={{
          width: scaleNormalizer(200),
          height: scaleNormalizer(200),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      </View>
      <Paragraph style={localStyles.text}>{`Remember, when investing your capital is at risk. The value of investments may fall as well as rise and you may get back less than you originally invested.`}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Investment Objectives'}</Subheading>
      <Paragraph style={localStyles.text}>{investmentObjectives}</Paragraph>
      <Paragraph style={localStyles.text}>{`Managed by: ${manager}`}</Paragraph>

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Fund Allocation'}</Subheading>
      <UnborderedTable
        data={allocationTableData}
      />
      <Paragraph style={localStyles.smallText}>{`* Your exact allocations may vary, but our goal is to invest your assets as close as possible to these allocations.`}</Paragraph>
      
      <ChargeBreakdown
        accountDescriptor={accountDescriptor}
        segment={segment}
        initialFeePercentage={0}
        annualFeePercentage={annualFeePercentage}
      />

      <ChargeCalculatorSlider
        accountDescriptor={accountDescriptor}
        segment={segment}
        annualFeePercentage={annualFeePercentage}
      />
      
      <RiskRating
        riskRating={riskRating}
        riskRatingScaleMax={riskRatingScaleMax}
      />

      <Subheading style={localStyles.subTitle}>{'Illustration'}</Subheading>
      <Paragraph style={localStyles.text}>{`Pension illustration for our portfolios:`}</Paragraph>
      <ExternalLinkButton
        url={genericPensionIllustrationUrl}
        usePdfViewer={true}
      >
        {`Illustration Document`}
      </ExternalLinkButton>

      {
        showAsset ?
        <Portal>
          <CardModal visible={showAsset} onDismiss={() => setShowAsset(undefined)} >
            <View style={layoutStyles.cardModalTitleContainer}>
              <View></View>
              <View style={layoutStyles.cardModalTitleTextContainer}></View>
              <View>
                <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={() => setShowAsset(undefined)} color={Colors.brand.purple1} />
              </View>
            </View>
            <View style={layoutStyles.cardModalContentContainer}>
              <ScrollView
                contentContainerStyle={{
                  paddingVertical: Sizing.x20,
                }}
                showsVerticalScrollIndicator={true}
              >
                <View style={layoutStyles.cardModalContentContainer}>
                  <InvestmentAssetModalContent
                    accountDescriptor={accountDescriptor}
                    asset={showAsset}
                    segment={segment}
                    omitPlatformCharges={true}
                  />
                </View>
              </ScrollView>
              <FooterButton onPress={() => setShowAsset(undefined)} mode={'text'} >{'Close'}</FooterButton>
            </View>
          </CardModal>
        </Portal>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  sliderOuterContainer: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})