import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddPensionBrandMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddPensionBrandVisible, showAdminAddPensionBrandVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddPensionBrandsModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
  //Show required fields on load
  useEffect(() => {
    trigger()
  }, [])


    const dispatch = useAppDispatch()
    const visible = useAppSelector(showAdminAddPensionBrandVisible)

    const [addPensionBrandMutation, { data: addedPensionBrand, isLoading: addPensionBrandIsLoading, error: addPensionBrandError }] = useAddPensionBrandMutation()
  
    const formObj = useForm<{
      key: string,
      name: string,
      description: string,
      raindropId: string,
      raindropName: string,
      logo: string,
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        key: '',
        name: '',
        description: '',
        raindropId: '',
        raindropName: '',
        logo: '',
      },
    })
    //Form refs for focussing
    const keyRef = useRef(null)
    const nameRef = useRef(null)
    const descriptionRef = useRef(null)
    const raindropIdRef = useRef(null)
    const raindropNameRef = useRef(null)
  
    const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
    register('logo', { required: true })
  
    const handleImagePick = (base64: string) => {
      setValue('logo', base64, { shouldDirty: true}) 
      trigger('logo')
    }
  
    const handleClearImage = () => {    
      setValue('logo', null, { shouldDirty: true})
    }
  
    const logo = watch('logo')
  
    const onSubmit = async attributes => {
      addPensionBrandMutation(attributes)
    }
  
    useEffect(() => {
      if (addedPensionBrand) {
        close()
      }
    }, [addedPensionBrand])
  
    const close = () => {
      dispatch(setShowAdminAddPensionBrandVisible(false))
    }
  
    const isLoading = addPensionBrandIsLoading
    const error: any = addPensionBrandError
  
    return (
      <ModalEditScreen
        formTitle={'Add New Pension Brand'}
        onDismiss={() => dispatch(setShowAdminAddPensionBrandVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new pension asset?'}
        error={error}
        errorTryAgain={addPensionBrandError ? handleSubmit(onSubmit) : undefined}
        errorCancel={close}
        isLoading={isLoading}
        loadingMessage={['Saving brand...']}
        buttonTitle={'Create Pension Brand'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Name shown to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => descriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 100,
        }}/>
        <ManagedTextInput
          ref={descriptionRef}
          name={'description'}
          formObj={formObj}
          label={'Description'}
          placeholder={'Optional description shown to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => keyRef.current?.focus()}
          rules={{
            required: false,
            minLength: 2,
            maxLength: 100,
        }}/>
        <ManagedTextInput
          ref={keyRef}
          name={'key'}
          formObj={formObj}
          label={'Brand Key'}
          placeholder={'Internal unique key'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => raindropIdRef.current?.focus()}
          rules={{
            required: true,
            minLength: 3,
            maxLength: 30,
          }}/>
        <ManagedTextInput
          ref={raindropIdRef}
          name={'raindropId'}
          formObj={formObj}
          label={'Raindrop Id'}
          placeholder={'Identifier in Raindrop system'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => raindropNameRef.current?.focus()}
          rules={{
            required: false,
            minLength: 36,
            maxLength: 36,
        }}/>
        <ManagedTextInput
          ref={raindropNameRef}
          name={'raindropName'}
          formObj={formObj}
          label={'Raindrop Name'}
          placeholder={'Brand name in Raindrop system'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 2,
            maxLength: 100,
        }}/>
        <View style={layoutStyles.inputContainer}>
          <ImagePickerButton
            disabled={!!logo}
            mode={'contained'}
            successHandler={handleImagePick}
            quality={1}
          >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
          {
            logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
          }
        </View>
        {
          logo ?
            <View style={{
              alignItems: 'center',
              paddingVertical: Sizing.x10,
            }}> 
              <View style={{
                borderRadius: Sizing.x5,
                borderStyle: 'dashed',
                borderColor: Colors.neutral.s400,
                borderWidth: Sizing.x2,
              }}>
                <Image
                  source={{ uri: logo }}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
            </View>
          : <></>
        }
      </ModalEditScreen>
    )
  }
  
  