import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { FooterInfo } from 'components/Utility/FooterInfo'
import { Loading } from 'components/Utility/Loading'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { ReactNode, useEffect, useRef } from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, StatusBar, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'

export type ProcessScreenProps = {
  children?: any
  error?: any
  errorTryAgain?: any
  isLoading?: boolean
  loadingUseHolidayGraphics?: boolean
  loadingMessage?: string []
  headline?: string
  subHeading?: string | ReactNode
  subHeadingNumberOfLines?: number
  subHeadingInfo?: ReactNode
  footerInfo?: ReactNode
  buttonTitle: string
  buttonAction: any
  buttonIconName?: string //MaterialCommunityIcons name
  showButton?: boolean
  enableButton?: boolean
  allowTextButton?: boolean
  textButtonTitle?: string
  textButtonAction?: any
}

export const ProcessScreen = (props: ProcessScreenProps) => {
  const { children, error, isLoading, loadingUseHolidayGraphics, loadingMessage, headline, subHeading, subHeadingNumberOfLines, subHeadingInfo, buttonTitle, buttonAction, buttonIconName, showButton, enableButton, allowTextButton, textButtonAction, textButtonTitle, errorTryAgain, footerInfo } = props
  const { colors: themeColors } = Paper.useAppTheme()
  const isWeb = platformIsWeb()
  const insets = useSafeAreaInsets()

  //Calculate the keyboard offset
  const headerHeight = Sizing.x120 //See ProcessHeader style processHeaderContainer
  const processScreenContainerPadding = Sizing.x30 //See style
  const keyboardVerticalOffset = headerHeight + processScreenContainerPadding

  const viewConstraints: any = getScreenWidthConstraints()
  
  //Flash scroll bars on entry
  const scrollRef = useRef<ScrollView>(null)
  useEffect(() => {
    scrollRef.current?.flashScrollIndicators()
  }, [])
  
  return (
      <>
      <StatusBar barStyle={'light-content'} />
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
          <View style={[backgroundStyles.pageContainer,
            {
              backgroundColor: isWeb ? Colors.brand.grey4 : undefined,
            }
          ]}>
            <BackgroundGradientPurple>
              <View style={[
                layoutStyles.mainContentContainer,
                localStyles.processScreenContainer,
                {
                  paddingBottom: insets.bottom,
                }
                ]} >{
                  error || isLoading ? <View style={{
                    ...Flex.column.start,
                  }}>
                    <View style={{...Flex.override.fill}}>
                    {
                      isLoading ? <Loading useHolidayGraphics={loadingUseHolidayGraphics} message={loadingMessage}/> : error ? <ErrorScreen errorTryAgain={errorTryAgain} error={error?.data} /> : <></>
                    }
                    </View>
                    <View style={{height: Sizing.x120}}></View>
                  </View>
                  :
                  <>
                    <View style={[
                      {
                        ...Flex.override.fill,
                        paddingHorizontal: Sizing.x30,
                      },
                      viewConstraints,
                    ]}>
                      <KeyboardAvoidingView 
                        style={{ flex:1 }} 
                        behavior={(Platform.OS === 'ios') ? "padding" : "height"}
                        keyboardVerticalOffset={keyboardVerticalOffset}
                      >
                        <ScrollView
                          ref={scrollRef}
                          contentContainerStyle={layoutStyles.scrollContainerContent}
                          keyboardShouldPersistTaps='handled'
                          showsVerticalScrollIndicator={true}
                        >
                          <View>
                            { headline ?
                              <View style={localStyles.headlineContainer}>
                                <Headline adjustsFontSizeToFit numberOfLines={2} style={{ alignSelf: 'center'}}>{headline}</Headline>
                              </View>
                              : <></>
                            }
                            { subHeading || subHeadingInfo ?
                              <View style={localStyles.subHeadingContainer}>
                                { subHeading
                                  ? <Subheading adjustsFontSizeToFit numberOfLines={subHeadingNumberOfLines || 2} style={{ alignSelf: 'center'}}>{subHeading}</Subheading>
                                  : <></>
                                }
                                {subHeadingInfo}
                              </View>
                              : <></>
                            }
                            {children}
                          </View>
                        </ScrollView>
                      </KeyboardAvoidingView>
                    </View>
                    <View style={[Flex.override.bottom, { width: '100%'}, viewConstraints]}>
                    { footerInfo ?
                        <FooterInfo>
                          {footerInfo}
                        </FooterInfo>
                        : <></>
                      }
                    </View>
                    <View style={{ ...Flex.override.bottom, marginTop: Sizing.x10 }}>
                      {allowTextButton ? <FooterButton
                        mode='text'
                        color={themeColors.accent}
                        onPress={textButtonAction}
                      >{textButtonTitle}</FooterButton> : <></>}

                      { showButton === undefined || showButton ?
                        <FooterButton
                          icon={buttonIconName}
                          onPress={buttonAction}
                          disabled={enableButton !== undefined && !enableButton}
                        >{buttonTitle}</FooterButton>
                        : <></>
                      }
                    </View>
                  </>
                }
              </View>
            </BackgroundGradientPurple>
          </View>
        {/* </TouchableWithoutFeedback> */}
      </>
  )
}

const localStyles = StyleSheet.create({
  processScreenContainer: {
    paddingTop: Sizing.x30,
  },
  headlineContainer: {
    paddingBottom: Sizing.x15,
  },
  subHeadingContainer: {
    paddingBottom: Sizing.x20,
  }
})