import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { mapMemberToEnrolmentJobMember } from 'lib/enrolmentHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { employerAppNavigate } from 'lib/RootNavigation'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMemberQuery } from 'store/apiSlice'
import { CreateGroupSchemeJobDto, GroupSchemeJobDataSetFormat, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerMemberDataId, setShowEmployerUpdateEnrolmentJobDataId, setSnackbarData, showEmployerUpdateEnrolmentJobDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerUpdateEnrolmentJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
 
    const [showDialog, setShowDialog] = useState(false)

    const currentScheme = useAppSelector(currentGroupScheme)
  
    const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
  
    const currentMemberId = useAppSelector(showEmployerUpdateEnrolmentJobDataId)
  
    const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )
  
    const { firstName, surname, title, gender, birthDate, nationalInsuranceNo, addressDetail } = member || {}
  
    const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError }] = useAddGroupSchemeJobWithDataMutation()
    
    const formObj = useForm<{
      employeeEmail: string
      employeeId?: string
      employmentStartDate?: string
      enrolmentDate?: string
      autoEnrolmentWindowOptOutDate?: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
  
    const { handleSubmit, setValue, setError, reset, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    useEffect(() => {
      if (member) {
        reset({
          employeeEmail: member?.employeeEmail,
          employeeId: member?.employeeId,
          employmentStartDate: member?.employmentStartDate,
          enrolmentDate: member?.enrolmentDate,
          autoEnrolmentWindowOptOutDate: member?.autoEnrolmentWindowOptOutDate,
        })
      }
    }, [member])
  
    //Form refs for focussing
    const employeeEmailRef = useRef(null)
    const employeeIdRef = useRef(null)
    const employmentStartDateRef = useRef(null)
    const enrolmentDateRef = useRef(null)
    const autoEnrolmentWindowOptOutDateRef = useRef(null)
  
    const onSubmit = async attributes => {
      const memberUpdate = {
        ...member,
        ...attributes,
      }
      const data: CreateGroupSchemeJobDto = {
        groupSchemeId: currentScheme.id,
        jobType: GroupSchemeJobType.MEMBER,
        dataSetFormat: GroupSchemeJobDataSetFormat.JSON,
        memberRecords: [mapMemberToEnrolmentJobMember(memberUpdate)]
      }
      addMemberJob(data)
    }
    
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Member Update Enrolment Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        closeAndGoToJobs()
      }
    }, [addedJob])
  
    const close = () => {
      dispatch(setShowEmployerUpdateEnrolmentJobDataId(undefined))
    }
    
    const goToMemberView = () => {
      dispatch(setShowEmployerUpdateEnrolmentJobDataId(undefined))
      dispatch(setCurrentEmployerMemberDataId(currentMemberId))
    }
  
    const closeAndGoToJobs = () => {
      employerAppNavigate(EmployerAppNavScreen.ENROLMENTS)
      close()
    }
  
    const isLoading = memberIsLoading || addJobIsLoading
    const error: any = memberError || addJobError
  
    const { colors: themeColors } = Paper.useAppTheme()
  
    const newEmail = watch('employeeEmail')
    const emailChanged = newEmail !== member?.employeeEmail
  
    return (
      <ModalEditScreen
        formTitle={'Update Employment Details'}
        onDismiss={() => dispatch(setShowEmployerUpdateEnrolmentJobDataId(undefined))}
        isDirty={isDirty}
        dismissDialogText={'Discard employment changes for this member?'}
        error={error}
        errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMember}
        errorCancel={close}
        isLoading={isLoading}
        loadingMessage={['Creating enrolment job...']}
        buttonTitle={'Submit Change'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid && userCanManageJobs}
        allowTextButton={true}
        textButtonTitle={'Return to Member Details'}
        textButtonAction={isDirty ? () => setShowDialog(true) : goToMemberView}
        footerInfo={userCanManageJobs ? undefined : <Text>{`You do not have permissions to update enrolled members.`}</Text>}
      >
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Member Details`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `Jarvis Identifier`,
              value: member?.id,
              copyableValue: member?.id,
            },
            {
              label: `National Insurance No`,
              value: formatNationalInsuranceNumber(member?.nationalInsuranceNo),
              copyableValue: member?.nationalInsuranceNo,
            },
            {
              label: `Name`,
              value: `${member?.firstName} ${member?.surname}`,
            },
            {
              label: `Gender`,
              value: member?.gender,
            },
            {
              label: `Date of Birth`,
              value: member?.birthDate ? formatUkDate(new Date(member?.birthDate)) : '',
            },
          ]}
          noContentDivider={true}
        />
  
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{'Employment/Enrolment Details'}</Subheading>
        <ManagedTextInput
          ref={employeeEmailRef}
          name={'employeeEmail'}
          keyboardType='email-address'
          formObj={formObj}
          label={'Email Address'}
          placeholder={'Their email address'}
          autoCapitalize={'none'}
          returnKeyType={'next'}
          submitHandler={() => employeeIdRef.current?.focus()}
          blurOnSubmit={false}
          informationMessage={emailChanged
            ? `If the member has not yet logged in, they will be sent a new invite`
            : undefined
          }
          rules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Invalid email address"
            },
            required: true,
            minLength: 2,
            maxLength: 100,
        }} />
        <ManagedTextInput
          ref={employeeIdRef}
          name={'employeeId'}
          formObj={formObj}
          label={'Employee ID'}
          placeholder={'Your payroll system identifier'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 2,
            maxLength: 40,
        }}/>
        <ManagedDateInput
          ref={employmentStartDateRef}
          name={'employmentStartDate'}
          formObj={formObj}
          label={'Employment Start Date'}
          blurOnSubmit={false}
          required={false}
          submitHandler={() => enrolmentDateRef.current?.focus()}
        />
        <ManagedDateInput
          ref={enrolmentDateRef}
          name={'enrolmentDate'}
          formObj={formObj}
          label={'Pension Scheme Enrolment Date'}
          blurOnSubmit={false}
          required={false}
          submitHandler={() => autoEnrolmentWindowOptOutDateRef.current?.focus()}
        />
        <ManagedDateInput
          ref={autoEnrolmentWindowOptOutDateRef}
          name={'autoEnrolmentWindowOptOutDate'}
          formObj={formObj}
          label={'Auto Enrolment Opt-Out Window End Date'}
          blurOnSubmit={false}
          required={true}
        />
        <ConfirmationDialog
          visible={showDialog}
          onCancel={() => setShowDialog(false)}
          title={'Are you sure?'}
          content={`Discard changes and return to member view?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Discard Changes'}
          onConfirm={goToMemberView}
        />
      </ModalEditScreen>
    )
  }
    
