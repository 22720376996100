import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { sortBy } from 'lodash'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddInvestmentPlanMutation, useGetAssetsQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddInvestmentPlanVisible, showAdminAddInvestmentPlanVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddInvestmentPlansModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showAdminAddInvestmentPlanVisible)

    const [addInvestmentPlanMutation, { data: addedInvestmentPlan, isLoading: addInvestmentPlanIsLoading, error: addInvestmentPlanError }] = useAddInvestmentPlanMutation()
  
    const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetAssetsQuery()

    //Show required fields when assets loaded
    useEffect(() => {
      if (assets) {
        trigger()
      }
    }, [assets])
  
    const formObj = useForm<{
      name: string
      code: string
      shortDescription: string
      longDescription: string
      priority: number
      sustainabilityRating: number
      assetId: string
      logo: string
      riskStatements: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        name: '',
        code: '',
        shortDescription: '',
        longDescription: '',
        priority: undefined,
        sustainabilityRating: undefined,
        assetId: undefined,
        logo: '',
        riskStatements: '',
      },
    })
    //Form refs for focussing
    const nameRef = useRef(null)
    const codeRef = useRef(null)
    const shortDescriptionRef = useRef(null)
    const longDescriptionRef = useRef(null)
    const priorityRef = useRef(null)
    const sustainabilityRatingRef = useRef(null)
    const riskStatementsRef = useRef(null)
  
    const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
    register('logo', { required: true })
  
    const handleImagePick = (base64: string) => {
      setValue('logo', base64, { shouldDirty: true}) 
      trigger('logo')
    }
  
    const handleClearImage = () => {
      setValue('logo', null, { shouldDirty: true})
    }
  
    const logo = watch('logo')
  
    const onSubmit = async attributes => {
      const { riskStatements } = attributes
      const riskStatementsArray = riskStatements.split(';')
  
      addInvestmentPlanMutation({
        ...attributes,
        riskStatements: riskStatementsArray,
      })
    }
  
    useEffect(() => {
      if (addedInvestmentPlan) {
        close()
      }
    }, [addedInvestmentPlan])
  
    const close = () => {
      dispatch(setShowAdminAddInvestmentPlanVisible(false))
    }
  
    const isLoading = assetsIsLoading || addInvestmentPlanIsLoading
    const error: any = assetsError || addInvestmentPlanError
  
    const assetOptions: AutoCompleteItem[]  = assets ? sortBy(assets.map((asset): AutoCompleteItem => {
      return {
        value: asset.id,
        label: asset.shortName,
        description: asset.manager,
        icon: () => <Image source={{ uri: asset?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
      }
    }), 'name')  : []
  
    return (
      <ModalEditScreen
        formTitle='Add New Investment Plan'
        onDismiss={() => dispatch(setShowAdminAddInvestmentPlanVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new investment plan?'}
        error={error}
        errorTryAgain={addInvestmentPlanError ? handleSubmit(onSubmit) : refetchAssets}
        errorCancel={close}
        isLoading={isLoading}
        loadingMessage={['Saving investment plan...']}
        buttonTitle={'Create Investment Plan'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Name visible to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => codeRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 20,
          }}/>
        <ManagedTextInput
          ref={codeRef}
          name={'code'}
          formObj={formObj}
          label={'Code'}
          placeholder={'Internal unique code'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => shortDescriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 10,
          }}/>
        <ManagedTextInput
          ref={shortDescriptionRef}
          name={'shortDescription'}
          formObj={formObj}
          label={'Short Description'}
          placeholder={'Short Description for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => longDescriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 100,
          }}/>
        <ManagedTextInput
          ref={longDescriptionRef}
          name={'longDescription'}
          formObj={formObj}
          label={'Long Description'}
          placeholder={'Long Description for display to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 200,
          }}/>
        <ManagedAutoCompleteInput
          name={'assetId'}
          formObj={formObj}
          label={'Underlying Investment Asset'}
          modalTitle={'Select Investment Asset'}
          placeholder={'Select an investment asset'}
          searchPlaceholder={'Search for an investment asset'}
          dataSet={assetOptions}
          required={true}
        />
        <View style={layoutStyles.inputContainer}>
          <ImagePickerButton
            disabled={!!logo}
            mode={'contained'}
            successHandler={handleImagePick}
            quality={1}
          >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
          {
            logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
          }
        </View>
        {
          logo ?
            <View style={{
              alignItems: 'center',
              paddingVertical: Sizing.x10,
            }}> 
              <View style={{
                borderRadius: Sizing.x5,
                borderStyle: 'dashed',
                borderColor: Colors.neutral.s400,
                borderWidth: Sizing.x2,
              }}>
                <Image
                  source={{ uri: logo }}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
            </View>
          : <></>
        }
        <Paragraph>{'Priority (for display order)'}</Paragraph>
        <ManagedIntegerInput
          ref={priorityRef}
          name={'priority'}
          formObj={formObj}
          label={'Priority'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => sustainabilityRatingRef.current?.focus()}
          required={true}
          minValue={1}
          maxValue={1000}
        />
        <Paragraph>{'Sustainability Rating (out of 5)'}</Paragraph>
        <ManagedIntegerInput
          ref={sustainabilityRatingRef}
          name={'sustainabilityRating'}
          formObj={formObj}
          label={'Sustainability Rating'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => riskStatementsRef.current?.focus()}
          required={true}
          minValue={1}
          maxValue={5}
        />
        <ManagedTextInput
          ref={riskStatementsRef}
          name={'riskStatements'}
          formObj={formObj}
          label={'Risk Statements'}
          placeholder={'Separate multiple statements by a semicolon'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 200,
          }}/>
      </ModalEditScreen>
    )
  }
  
  
