import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation } from 'store/apiSlice'
import { Sizing } from 'styles'

export const BulkTransferIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const [ updateClient] = useUpdateMeMutation()
  const [dialogVisible, setDialogVisible] = React.useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)


  const next = () => {
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'transfer_start',
      isRetransfer: false,
      outcome: 'success',
    })
    
    rootNavigate('BulkTransferProcessStack', route?.params)
  }

  const refuse = () => {
    updateClient({
      onboardingFlags: {
        consolidate: true,
      },
    })
    ampli.transfersSetup({
      skipped: true,
    })
    hideDialog()
    goToOnboardingTasks(fromOnboarding)
  }

  return (
    <ProcessIntroScreen
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      refuseButtonAction={fromOnboarding ? showDialog : undefined}
      refuseButtonText={`Nothing to consolidate?`}
      illustrationFilename={'simplify_your_portfortlio.png'}
      headline={`Find & Combine Pensions`}
      subHeading={`Locate your old pensions and consolidate into your ${JAR_NAME_PERSONAL}`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.CONSOLIDATION_RATIONALE} />}
    >
      <AppIllustration filename={'info.png'} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      <Paragraph style={{ textAlign: 'left' }}>{`Our universal transfer process can help you:`}</Paragraph>
      <BulletItem style={{ textAlign: 'left' }}>{`Immediately start transfer of pensions for which you know the details`}</BulletItem>
      <BulletItem style={{ textAlign: 'left' }}>{`Request our team to find pensions for you, if you can't find all the information`}</BulletItem>
      
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`We'll tick this task off, but you can always add/consolidate old pensions in the Transfers area`}
        onCancel={hideDialog}
        onConfirm={refuse}
        confirmLabel={'Complete task'}
      />
    </ProcessIntroScreen>
  )
}
