import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { buildAddressDescription } from 'lib/addressHelpers'
import { CORPORATION_TAX_REF_MASK, PAYE_TAX_REF_MASK } from 'lib/constants'
import { getAffliliateAutoCompleteOptions } from 'lib/referenceDataHelpers'
import { getBestName, getBestNameAndEmail } from 'lib/userHelpers'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Clipboard, Image, View } from 'react-native'
import { useAddGroupOrganizationMandateMutation, useDeleteGroupOrganizationMandateMutation, useGetAffiliatesQuery, useGetBankConnectionsQuery, useGetGroupOrganizationMandateQuery, useGetGroupOrganizationQuery, useRefreshGroupOrganizationApiKeyMutation, useRevokeGroupOrganizationApiKeyMutation, useUpdateGroupOrganizationMutation } from 'store/apiSlice'
import { PaymentMethod } from 'store/dto/account.dto'
import { GroupOrganizationMandateStatus, GroupOrganizationStatus } from 'store/dto/group-organization.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupOrganizationDataId, setCurrentAdminGroupOrganizationDataId, setCurrentAdminGroupOrganizationSetRolesDataId, setSnackbarData } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AdminEditGroupOrganizationsModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {

  const dispatch = useAppDispatch()
  const currentGroupOrganizationId = useAppSelector(currentAdminGroupOrganizationDataId)

  const [updateDialogVisible, setUpdateDialogVisible] = useState(false)
  const [discardDialogVisible, setDiscardDialogVisible] = useState(false)

  const { data: groupOrganization, isLoading: groupOrganizationIsLoading, error: groupOrganizationError } = useGetGroupOrganizationQuery(currentGroupOrganizationId, { skip: !currentGroupOrganizationId } )
  const { data: mandate, isLoading: mandateIsLoading, error: mandateError } = useGetGroupOrganizationMandateQuery(currentGroupOrganizationId, { skip: !currentGroupOrganizationId } )
  const [updateGroupOrganization, { data: updatedGroupOrganization, isLoading: groupOrganizationUpdateIsLoading, error: groupOrganizationUpdateError }] = useUpdateGroupOrganizationMutation()
  const [activateGroupOrganization, { data: activatedGroupOrganization, isLoading: groupOrganizationActivationIsLoading, error: groupOrganizationActivationError }] = useUpdateGroupOrganizationMutation()
  const [inactivateGroupOrganization, { data: inactivatedGroupOrganization, isLoading: groupOrganizationInactivationIsLoading, error: groupOrganizationInactivationError }] = useUpdateGroupOrganizationMutation()
  const [refreshGroupOrganizationApiKey, { isLoading: groupOrganizationRefreshApiKeyIsLoading, error: groupOrganizationRefreshApiKeyError }] = useRefreshGroupOrganizationApiKeyMutation()
  const [revokeGroupOrganizationApiKey, { isLoading: groupOrganizationRevokeApiKeyIsLoading, error: groupOrganizationRevokeApiKeyError }] = useRevokeGroupOrganizationApiKeyMutation()
  const [addMandate, { data: addedMandate, isLoading: addMandateIsLoading, error: addMandateError }] = useAddGroupOrganizationMandateMutation()
  const [deleteMandate, { isLoading: deleteMandateIsLoading, error: deleteMandateError }] = useDeleteGroupOrganizationMandateMutation()

  const hasApiKey = !!groupOrganization?.apiKey

  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()
  const { data: affiliates, isLoading: affiliatesIsLoading, error: affiliatesError, refetch: refetchAffiliates } = useGetAffiliatesQuery()
  const affiliateOptions = getAffliliateAutoCompleteOptions(affiliates)

  const copyToClipboard = async (text) => {
    await Clipboard.setString(text)
    dispatch(setSnackbarData({
      message: `Copied: "${text}"`,
      iconName: 'content-copy',
    }))
  }

  const formObj = useForm<{
    name: string
    displayName: string
    logo: string
    payeReference: string
    taxReference: string
    affiliateId?: string
    apiDisabled: boolean
    mandateStatus?: GroupOrganizationMandateStatus
    defaultContributionMethod: PaymentMethod
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, setValue, reset, setError, watch, trigger, formState: { isDirty, isValid } } = formObj

  useEffect(() => {
    if (groupOrganization) {
      reset({
        name: groupOrganization?.name,
        displayName: groupOrganization?.displayName,
        logo: groupOrganization?.logo,
        payeReference: groupOrganization?.payeReference,
        taxReference: groupOrganization?.taxReference,
        affiliateId: groupOrganization?.affiliateId,
        apiDisabled: groupOrganization?.apiDisabled,
        mandateStatus: groupOrganization?.mandateStatus,
        defaultContributionMethod: groupOrganization?.defaultContributionMethod,  
      })
    }
  }, [groupOrganization])

  //Form refs for focussing
  const nameRef = useRef(null)
  const displayNameRef = useRef(null)
  const payeReferenceRef = useRef(null)
  const taxReferenceRef = useRef(null)

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger('logo')
  }

  const handleClearImage = () => {
    setValue('logo', null, { shouldDirty: true})
  }

  const logo = watch('logo')

  const onSubmit = async attributes => {
    const {
      mandateStatus,
      defaultContributionMethod,
      ...remaining
    } = attributes

    await updateGroupOrganization({
      id: groupOrganization.id,
      mandateStatus: groupOrganization?.status === GroupOrganizationStatus.PENDING ? mandateStatus : undefined,
      defaultContributionMethod: groupOrganization?.status === GroupOrganizationStatus.ACTIVE ? defaultContributionMethod : undefined,
      ...remaining,
    })
    setUpdateDialogVisible(false)
  }

  useEffect(() => {
    if (updatedGroupOrganization) {
      close()
    }
  }, [updatedGroupOrganization])

  const close = () => {
    dispatch(setCurrentAdminGroupOrganizationDataId(undefined))
  }

  const handleRefreshApiKey = () => {
    refreshGroupOrganizationApiKey(groupOrganization?.id)
  }

  const handleRevokeApiKey = () => {
    revokeGroupOrganizationApiKey(groupOrganization?.id)
  }
  
  const handleAddMandate = () => {
    addMandate({
      id: groupOrganization?.id,
      sendEmail: true,
    })
  }

  const handleDeleteMandate = () => {
    deleteMandate(groupOrganization?.id)
  }

  const handleSetActive = async () => {
    await activateGroupOrganization({
      id: groupOrganization.id,
      status: GroupOrganizationStatus.ACTIVE,
    })
  }
    
  const handleSetInactive = async () => {
    await inactivateGroupOrganization({
      id: groupOrganization.id,
      status: GroupOrganizationStatus.INACTIVE,
    })
  }

  const isLoading = banksIsLoading || affiliatesIsLoading || groupOrganizationIsLoading || groupOrganizationUpdateIsLoading || groupOrganizationRefreshApiKeyIsLoading || groupOrganizationRevokeApiKeyIsLoading || groupOrganizationActivationIsLoading || groupOrganizationInactivationIsLoading || mandateIsLoading || addMandateIsLoading || deleteMandateIsLoading
  const error: any = banksError || affiliatesError || groupOrganizationError || groupOrganizationUpdateError || groupOrganizationActivationError || groupOrganizationInactivationError || addMandateError || deleteMandateError

  const { colors: themeColors } = Paper.useAppTheme()

  const apiDisabledOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Yes',
    },
    {
      value: true,
      label: 'No',
    },
  ]

  const mandateSetupOptions: ManagedSimpleChoiceItem[] = [
    {
      value: GroupOrganizationMandateStatus.NOT_REQUESTED,
      label: 'Decide Later',
    },
    {
      value: GroupOrganizationMandateStatus.REQUESTED,
      label: 'Requested',
    },
    {
      value: GroupOrganizationMandateStatus.NOT_REQUIRED,
      label: 'Not Required',
    },
  ]
  
  const defaultContributionMethodOptions: ManagedSimpleChoiceItem[] = [
    {
      value: PaymentMethod.BANK_TRANSFER,
      label: 'Manual Bank Transfer',
    },
    {
      value: PaymentMethod.DIRECT_DEBIT,
      label: 'Direct Debit',
    },
  ]

  const sourceBank = banks ? banks.find(bank => {
    return bank.id === groupOrganization?.bankConnectionId
  }) : undefined

  const isPending = groupOrganization?.status === GroupOrganizationStatus.PENDING
  const isActive = groupOrganization?.status === GroupOrganizationStatus.ACTIVE

  const mandateCancellable = [
    GroupOrganizationMandateStatus.ACTIVE,
    GroupOrganizationMandateStatus.AWAITING_AUTH,
    GroupOrganizationMandateStatus.PENDING,
  ].includes(groupOrganization?.mandateStatus)

  const goToUserRolesView = () => {
    dispatch(setCurrentAdminGroupOrganizationDataId(undefined))
    dispatch(setCurrentAdminGroupOrganizationSetRolesDataId(currentGroupOrganizationId))
  }

  return (
    <ModalEditScreen
      formTitle={'Edit Group Organization Details'}
      onDismiss={() => dispatch(setCurrentAdminGroupOrganizationDataId(undefined))}
      error={error}
      errorCancel={close}
      errorTryAgain={banksError ? refetchBanks
        : affiliatesError ? refetchAffiliates
        : groupOrganizationActivationError ? handleSetActive
        : groupOrganizationInactivationError ? handleSetInactive
        : groupOrganizationRefreshApiKeyError ? handleRefreshApiKey
        : groupOrganizationRevokeApiKeyError ? handleRevokeApiKey
        : addMandateError ? addMandate
        : deleteMandateError ? deleteMandate
        : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={groupOrganizationUpdateIsLoading ? ['Saving group organization...'] : undefined}
      buttonTitle={'Save Changes'}
      buttonAction={() => setUpdateDialogVisible(true)}
      showButton={true}
      enableButton={isDirty && isValid}
      allowTextButton={isActive}
      textButtonTitle={'Manage Users & Roles'}
      textButtonAction={isDirty ? () => setDiscardDialogVisible(true) : goToUserRolesView}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupOrganization?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: groupOrganization?.name,
          },
          {
            label: `Company No`,
            value: groupOrganization?.companyNo,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Current Status`,
            value: groupOrganization?.status,
          },
          {
            label: `Status Explanation`,
            value: isPending ? `Organization is currently onboarding - employer invites have not been sent and schemes cannot yet be added.`
              : groupOrganization?.status === GroupOrganizationStatus.ACTIVE ? `Employer invites have been sent and new schemes can be added.`
              : `Organization is inactive and can no longer be accessed by the employer.`
          }
        ]}
        noContentDivider={true}
      />
      {
        groupOrganization?.status === GroupOrganizationStatus.ACTIVE
        ? <Button
            onPress={handleSetInactive}
            mode={'text'}
            confirmation={{
              requiresConfirmation: true,
              confirmLabel: 'Deactivate',
              dialogTitle: 'Are you sure?',
              dialogContent: 'This will prevent access by the employer to any schemes related to this organization.\n\nThis change must also be communicated to Digi.'
            }}
            >
              {'Deactivate Organisation'}
          </Button>
        : <Button
            onPress={handleSetActive}
            mode={'text'}
            confirmation={{
              requiresConfirmation: true,
              confirmLabel: isPending ? 'Activate' : 'Reactivate',
              dialogTitle: 'Are you sure?',
              dialogContent: isPending
                ? 'This will enable access to the employer, and an invite will be sent to the primary contact. It will then be possible to add schemes to this organization.\n\nThis change will only be accepted if the employer in Digi is in Active status.'
                : 'This will re-enable access by the employer to any schemes related to this organization.\n\nAn invite will be (re)sent to the primary contact.\n\nThis change must also be communicated to Digi.',
            }}
            >
              {isPending ? 'Activate Organisation' : 'Reactivate Organisation'}
          </Button>
      }

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Addresses'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Registered Office Address`,
            value: buildAddressDescription(groupOrganization?.registeredOfficeAddressDetail),
          },
          {
            label: `Trading Address`,
            value: buildAddressDescription(groupOrganization?.tradingAddressDetail),
          },
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Contribution Source'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Bank Name`,
            value: sourceBank?.name,
          },
          {
            label: `Sort Code`,
            value: groupOrganization?.bankDetails?.sortCode,
            copyableValue: true,
          },
          {
            label: `Account Number`,
            value: groupOrganization?.bankDetails?.accountNumber,
            copyableValue: true,
          },
          {
            label: `Bank Account Name`,
            value: groupOrganization?.bankDetails?.accountName,
          },
          {
            label: `Default Contribution Method`,
            value: groupOrganization?.defaultContributionMethod,
          },
          {
            label: `Direct Debit Mandate Status`,
            value: groupOrganization?.mandateStatus,
          },
        ]}
        noContentDivider={true}
      />
      {
        groupOrganization?.mandateStatus === GroupOrganizationMandateStatus.ACTIVE && mandate?.url
          ? <ExternalLinkButton
              url={mandate?.url}
              labelStyle={{
                textDecorationLine: 'none',
                textTransform: 'none',
              }}
              color={Colors.brand.purple1}
              iconName={'file-eye-outline'}
              iconColor={Colors.brand.purple1}
            >{'View Mandate'}</ExternalLinkButton>
          : <></>
      }
      {
        groupOrganization?.status === GroupOrganizationStatus.ACTIVE
          ? <Button
              onPress={mandateCancellable
                ? handleDeleteMandate
                : handleAddMandate
              }
              mode={'text'}
              confirmation={{
                requiresConfirmation: true,
                dialogTitle: 'Are you sure?',
                dialogContent: mandateCancellable
                  ? `Cancel the Direct Debit Mandate for this employer?\n\The Default Contribution Method for this employer will change to ${PaymentMethod.BANK_TRANSFER}`
                  : `Send a Direct Debit mandate authorisation request to organization administrator(s)?\n\nOnce approved, the Default Contribution Method for this employer will change to ${PaymentMethod.DIRECT_DEBIT}`
              }}
            >
            {
              mandateCancellable
                ? 'Cancel Direct Debit Mandate'
                : 'Setup Direct Debit Mandate'
            }
          </Button>
          : <></>
      }

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Primary Contact User'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupOrganization?.primaryContactUser?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: getBestName(groupOrganization?.primaryContactUser),
          },
          {
            label: `Email`,
            value: groupOrganization?.primaryContactUser?.email,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Advisor'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Name`,
            value: groupOrganization?.advisorName,
          },
          {
            label: `Email`,
            value: groupOrganization?.advisorEmail,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'API Connection'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `API Key`,
            value: groupOrganization?.apiKey || 'n/a',
            copyableValue: !!groupOrganization?.apiKey,
          },
        ]}
        noContentDivider={true}
      />
      <Button
        onPress={handleRefreshApiKey}
        mode={'text'}
        confirmation={{
          requiresConfirmation: true,
          dialogTitle: 'Are you sure?',
          dialogContent: groupOrganization?.apiKey
            ? 'Refreshing the API Key will revoke the existing key and the organization will need to update their systems to use the new value.'
            : 'Generating an API Key will provide access to the API to upload files once the key value is shared with them.'
        }}
      >
        {groupOrganization?.apiKey ? 'Refresh API Key' : 'Generate API Key'}
      </Button>
      {
        groupOrganization?.apiKey
          ? <Button
              onPress={handleRevokeApiKey}
              mode={'text'}
              confirmation={{
                requiresConfirmation: true,
                dialogTitle: 'Are you sure?',
                dialogContent: 'Revoking the API Key will revoke the existing key and the organization will no longer be able to access the API to upload files.'
              }}
            >
            {'Revoke API Key'}
          </Button>
          : <></>
      }

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      {
        hasApiKey ? 
          <>
            <Paragraph>{'API Access Enabled?'}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'apiDisabled'}
              formObj={formObj}
              options={apiDisabledOptions}
              required={hasApiKey}
            />
          </>
          : <></>
      }
      <Subheading>{'Basic Details'}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Legal organization name'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
        }}/>
      <ManagedTextInput
        ref={displayNameRef}
        name={'displayName'}
        formObj={formObj}
        label={'Display Name'}
        placeholder={'Short Organization name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
        }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <ManagedTextInput
        ref={payeReferenceRef}
        name={'payeReference'}
        formObj={formObj}
        label={'PAYE Reference'}
        placeholder={'HMRC PAYE Reference in format 123/AB456'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        mask={{
          type: 'custom',
          options: {
            mask: PAYE_TAX_REF_MASK,
          }
        }}
        rules={{
          required: false,
          minLength: 9,
          maxLength: 14,
      }}/>
      <ManagedTextInput
        ref={taxReferenceRef}
        name={'taxReference'}
        formObj={formObj}
        label={'Corporation Tax Reference (UTR)'}
        placeholder={'HMRC Tax Reference'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        mask={{
          type: 'custom',
          options: {
            mask: CORPORATION_TAX_REF_MASK,
          }
        }}
        rules={{
          required: false,
          minLength: 10,
          maxLength: 10,
      }}/>
      
      <Subheading>{'Affiliation'}</Subheading>
      <ManagedAutoCompleteInput
        name={'affiliateId'}
        formObj={formObj}
        label={'Affiliate'}
        required={false}
        dataSet={affiliateOptions}
      />
      <Subheading>{'Contribution Settings'}</Subheading>
      {
        groupOrganization?.status === GroupOrganizationStatus.PENDING ?
          <>
            <Paragraph>{'Direct Debit Mandate Setup?'}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'mandateStatus'}
              formObj={formObj}
              options={mandateSetupOptions}
              required={true}
            />
          </>
        : groupOrganization?.status === GroupOrganizationStatus.ACTIVE && groupOrganization?.mandateStatus === GroupOrganizationMandateStatus.ACTIVE ?
          <>
            <Paragraph>{'Default Contribution Method'}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'defaultContributionMethod'}
              formObj={formObj}
              options={defaultContributionMethodOptions}
              required={true}
            />
          </>
        : groupOrganization?.status === GroupOrganizationStatus.ACTIVE ?
          <>
            <Paragraph>{'Only available when an active mandate exists.'}</Paragraph>
          </>
        : <>
            <Paragraph>{'Not available for inactive organisations.'}</Paragraph>
          </>
      }
      <ConfirmationDialog
        visible={updateDialogVisible}
        title={'Are you sure?'}
        content={`If you have made changes to the PAYE Reference or Corporation Tax Reference, these changes must be communicated to Digi.`}
        onCancel={() => setUpdateDialogVisible(false)}
        onConfirm={handleSubmit(onSubmit)}
      />
      <ConfirmationDialog
        visible={discardDialogVisible}
        onCancel={() => setDiscardDialogVisible(false)}
        title={'Are you sure?'}
        content={`Discard changes and edit user roles instead?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Discard Changes'}
        onConfirm={goToUserRolesView}
      />
    </ModalEditScreen>
  )
}


