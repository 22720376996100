import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { UserDto, UserMetadata } from 'store/dto/user.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientSetup_07_OnboardingChoice = ({ route, navigation }) => {
  const { nextScreen, user, metadata }: { nextScreen: string, user: UserDto, metadata: UserMetadata } = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ preferredOnboardingPhase: OnboardingPhase }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      preferredOnboardingPhase: workingClientData?.preferredOnboardingPhase || user?.preferredOnboardingPhase,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  const skip = () => {
    navigation.navigate(nextScreen)
  }

  let options: ManagedCardChoiceInputOption[] = [
    {
      value: OnboardingPhase.PLAN,
      title: 'Plan Your Retirement',
      description: 'Find out your achievable retirement age',
      illustrationFilename: 'plan_retirement.png',
    },
    {
      value: OnboardingPhase.CONSOLIDATE,
      title: 'Combine Old Pensions',
      description: `Find old pensions and consolidate into one place`,
      illustrationFilename: 'consolidate_old_pensions.png',
    },
    {
      value: OnboardingPhase.CONTRIBUTE,
      title: 'Start Saving',
      description: `Start putting money away for your future`,
      illustrationFilename: 'start_contributing.png',
    },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Finally, what's your top priority?`}
      subHeading={`This will help us customise your Jarvis journey and prioritise what's important to you`}
      allowTextButton={true}
      textButtonTitle={`Not sure yet`}
      textButtonAction={skip}
      
    >
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'preferredOnboardingPhase'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        options={options}
      />
    </ProcessScreen>
  )
}
