import { GroupSchemeJobDataSetFormat, GroupSchemeJobDto, GroupSchemeJobStatus, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { formatISODateOrUndefined, getActionDate } from './dateHelpers'

const unfinishedStatuses: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.PENDING,
  GroupSchemeJobStatus.PROCESSING,
  GroupSchemeJobStatus.INVESTIGATING,
]

const finishedStatuses: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.COMPLETED,
  GroupSchemeJobStatus.COMPLETED_WITH_ERRORS,
  GroupSchemeJobStatus.REJECTED,
]

const successStatuses: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.COMPLETED,
  GroupSchemeJobStatus.COMPLETED_WITH_ERRORS,
]

export const isUnfinishedGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return unfinishedStatuses.includes(job?.status)
}

export const isFinishedGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return finishedStatuses.includes(job?.status)
}

export const isSuccessfulGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return successStatuses.includes(job?.status)
}

export const getGroupSchemeJobListInfo = (job: GroupSchemeJobDto, forHistory: boolean): {
  iconName: string,
  description: string,
  formatString: string,
  uploadedByString: string,
  message: string
} => {
  return {
    iconName: getGroupSchemeJobIconName(job),
    description: getGroupSchemeJobDescription(job),
    formatString: getGroupSchemeJobDataFormat(job),
    uploadedByString: getGroupSchemeUploadInformation(job),
    message: forHistory
      ? getGroupSchemeJobHistoryMessage(job)
      : getGroupSchemeJobActionMessage(job),
  }
}

export const getGroupSchemeUploadInformation = (job: GroupSchemeJobDto): string => {
  const { creator, createdAt } = job || {}
  const { firstName, surname, id } = creator || {}

  return `Uploaded by ${id ? `${firstName} ${surname}` : 'API'} (${getActionDate(createdAt)})`
}

export const getGroupSchemeJobDescription = (job: GroupSchemeJobDto): string => {
  const { description, dataSetFormat, jobType, creator, originalFilename } = job || {}

  if (description) {
    return description
  }

  return dataSetFormat === GroupSchemeJobDataSetFormat.PAPDIS
    ? originalFilename
    : jobType === GroupSchemeJobType.MEMBER
      ? 'Member Enrolment Submission'
      : 'Contribution Submission'
}

export const getGroupSchemeJobDataFormat = (job: GroupSchemeJobDto): string => {
  const { dataSetFormat, creator } = job || {}
  const { id } = creator || {}
  return dataSetFormat === GroupSchemeJobDataSetFormat.PAPDIS
    ? 'PAPDIS File'
    : dataSetFormat === GroupSchemeJobDataSetFormat.JSON
      ? id
        ? 'Manual Upload'
        : 'API Submission'
      : 'Other Format'
}

export const getGroupSchemeJobIconName = (job: GroupSchemeJobDto): string => {
  const { status, resultAcknowledged } = job || {}
  if (resultAcknowledged) {
    return status === GroupSchemeJobStatus.REJECTED
      ? 'archive-cancel-outline'
      : 'archive-check-outline'
  }

  switch(status) {
    case GroupSchemeJobStatus.COMPLETED:
      return 'file-check-outline'
    case GroupSchemeJobStatus.REJECTED:
      return 'file-cancel-outline'
    case GroupSchemeJobStatus.INVESTIGATING:
      return 'file-question-outline'
    case GroupSchemeJobStatus.COMPLETED_WITH_ERRORS:
      return 'file-alert-outline'
    case GroupSchemeJobStatus.PROCESSING:
      return 'file-clock-outline'
    case GroupSchemeJobStatus.PENDING:
      return 'file-clock-outline'
    default:
      return 'file-outline'
  }  
}

export const getGroupSchemeJobActionMessage = (job: GroupSchemeJobDto): string => {
  const { expectedCompleteAt, createdAt, status } = job || {}
  return status === GroupSchemeJobStatus.COMPLETED ? `Click to View Result`
  : status === GroupSchemeJobStatus.COMPLETED_WITH_ERRORS ? `Click to View Errors`
  : status === GroupSchemeJobStatus.REJECTED ? `Click for Details`
  : status === GroupSchemeJobStatus.INVESTIGATING ? `Click for More Information`
  : expectedCompleteAt ? `Estimated Completion: ${getActionDate(expectedCompleteAt)}`
  : `Estimated Completion: Unknown`
}

export const getGroupSchemeJobHistoryMessage = (job: GroupSchemeJobDto): string => {
  const { resultAcknowledged, status: statusText } = job || {}

  return resultAcknowledged ? `Archived (${statusText})` : statusText
}