import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Subheading } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { getActionDate } from 'lib/dateHelpers'
import { compact, concat } from 'lodash'
import { default as React, useState } from 'react'
import { useCloseExceptionMutation, useGetExceptionQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminExceptionDataId, setCurrentAdminExceptionDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const AdminExceptionEditModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {

  const dispatch = useAppDispatch()
  const currentException = useAppSelector(currentAdminExceptionDataId)

  const currentExceptionId = useAppSelector(currentAdminExceptionDataId)

  const [closeDialogVisible, setCloseDialogVisible] = useState(false)

  const { isLoading: exceptionIsLoading, error: exceptionError, data: exception, refetch: refetchException } = useGetExceptionQuery(String(currentExceptionId), { skip: !currentExceptionId } )
  const [closeException, { data: closedException, isLoading: closeExceptionIsLoading, error: closeExceptionError }] = useCloseExceptionMutation()

  const handleCloseException = () => {
    closeException(currentExceptionId)
    close()
  }

  const close = () => {
    dispatch(setCurrentAdminExceptionDataId(undefined))
  }

  const isLoading = exceptionIsLoading || closeExceptionIsLoading
  const error: any = exceptionError || closeExceptionError

  const { colors: themeColors } = Paper.useAppTheme()

  const { id, source, status, createdAt, message, errorMessage, systemArea, systemAreaAction, eventType, externalSystem, externalSystemAction, notes, referencedEntities, validationErrors } = exception || {}

  const errorData: UnborderedTableRow[] = concat(
    [
      {
        label: `Error Message`,
        value: errorMessage,
      },
    ],
    validationErrors?.length ? validationErrors.map(validationError => {
      const { errorMessage, fieldName, type } = validationError
      return {
        label: errorMessage,
        value: `${type} / ${fieldName}`,
      }
    }) : [],
  )
  
  const entityData: UnborderedTableRow[] = referencedEntities?.length ? referencedEntities.map(referencedEntity => {
    const { entityId, entityName } = referencedEntity
    return {
      label: entityName,
      value: entityId,
      copyableValue: true,
    }
  }) : []

  return (
    <ModalEditScreen
      formTitle={'Exception Management'}
      onDismiss={() => dispatch(setCurrentAdminExceptionDataId(undefined))}
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={exceptionIsLoading ? undefined : ['Closing...']}
      buttonTitle={'Close'}
      buttonAction={close}
      showButton={true}
      enableButton={true}
      allowTextButton={true}
      textButtonAction={() => setCloseDialogVisible(true)}
      textButtonTitle={'Mark Exception as Actioned'}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: id,
            copyableValue: true,
          },
          {
            label: `Source System`,
            value: source,
          },
          {
            label: `Occurred`,
            value: getActionDate(createdAt, false, true),
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Current Status'}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Status`,
            value: status,
          },
          notes ? {
            label: `Notes`,
            value: notes,
          } : undefined,
        ])}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{'Details'}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `System Area`,
            value: systemArea,
          },
          {
            label: `System Area Action`,
            value: systemAreaAction,
          },
          {
            label: `Message`,
            value: message,
          },
          eventType ? {
            label: `Event Type`,
            value: eventType,
          } : undefined,
          externalSystem ? {
            label: `Related External System`,
            value: externalSystem,
          } : undefined,
          externalSystemAction ? {
            label: `Related External System Action`,
            value: externalSystemAction,
          } : undefined,
        ])}
        noContentDivider={true}
      />
      {
        errorMessage || validationErrors?.length
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{'Error Details'}</Subheading>
              <UnborderedTable
                data={errorData}
                noContentDivider={true}
              />
            </>
          : <></>
      }
      {
        referencedEntities?.length
          ? <>
            <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{'Referenced Entities'}</Subheading>
              <UnborderedTable
                data={entityData}
                noContentDivider={true}
              />

            </>
          : <></>
      }
      <ContentDivider />
      <ConfirmationDialog
        visible={closeDialogVisible}
        title={'Are you sure?'}
        content={`This action cannot be undone!\n\nPlease confirm that you want to close this exception.`}
        onCancel={() => setCloseDialogVisible(false)}
        onConfirm={handleCloseException}
        confirmLabel={'Mark Actioned'}
        cancelLabel={'Go Back'}
      />
    </ModalEditScreen>
  )
}
