import { GroupSchemeEnrolmentEnrolmentEndReason, GroupSchemeEnrolmentStatus, GroupSchemeRequestStatus, GroupSchemeRequestType, PaymentMethod } from './account.dto';
import { Address, BaseDatedDto, Gender, SimpleUserDto, Title } from './base.dto';
import { BankDetails } from './client.dto';
import { GroupOrganizationDto, GroupOrganizationMandateStatus, GroupOrganizationRole, GroupSchemeRole } from './group-organization.dto';
import { GroupPortfolioDto } from './group-portfolio.dto';
import { InviteSearchStatus, InviteStatus } from './invite.dto';
import { UserMetadata } from './user.dto';

export enum GroupSchemeStatus {
  ONBOARDING = 'Onboarding',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum GroupSchemeEnrolmentPayrollFrequency {
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
  FOUR_WEEKLY = 'Four-Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  BI_ANNUALLY = 'Bi-Annually',
  ANNUALLY = 'Annually',
}

export enum GroupSchemeSalarySacrificeMode {
  MANDATED = 'Mandated',
  ELECTIVE = 'Elective',
  UNAVAILABLE = 'Unavailable',
}

export interface NiCheckMatchesResultDto {
  matches: boolean
}

export enum GroupSchemeOption {
  AUTO_CONFIRM = 'Auto Confirm',
  AUTO_ARCHIVE = 'Auto Archive',
}

export interface GroupSchemeBaseDto extends BaseDatedDto {
  type: string
  id: string
  name: string
  digiId: string
  status: GroupSchemeStatus
  tprLetterCode?: string
  dutiesStartDate?: string
  defaultEmployerContributionPercentage?: number
  defaultEmployeeContributionPercentage?: number
  salarySacrificeMode: GroupSchemeSalarySacrificeMode
  employerPensionSchemeReference: string
  isAutoEnrolment: boolean //TBC
  defaultPayrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  defaultContributionFrequency: GroupSchemeEnrolmentPayrollFrequency
  organizationId: string
  segmentId: string
  defaultGroupPortfolioId: string
  defaultEnrolmentName: string
  defaultTreatContributionsAsRegular: boolean
  previousPensionProviderBrandKey?: string
  previousPensionProviderBrandName?: string
  enabledRequestTypes?: GroupSchemeRequestType[]
  defaultContributionMethod?: PaymentMethod
  schemeOptions?: GroupSchemeOption[]
}

export interface GroupSchemeDto extends GroupSchemeBaseDto {
  organization: GroupOrganizationDto
  defaultGroupPortfolio: GroupPortfolioDto
}

export interface UserGroupSchemeDto extends GroupSchemeBaseDto {
  organizationDetails: UserGroupSchemeOrganizationDetailsDto
  userSchemeRole?: GroupSchemeRole
}

export interface UserGroupSchemeOrganizationDetailsDto {
  displayName: string
  companyNo: string
  logoPath: string
  bankDetails: BankDetails
  defaultContributionMethod: PaymentMethod
  mandateStatus: GroupOrganizationMandateStatus
}

export interface GroupSchemeFilterDto {
  search?: string
  status?: GroupSchemeStatus
  organizationId?: string
}

export interface UserGroupSchemeFilterDto {
  search?: string
  groupSchemeId?: string
}

export interface CreateGroupSchemeDto {
  name: string
  status: GroupSchemeStatus
  tprLetterCode?: string
  dutiesStartDate?: string
  defaultEmployerContributionPercentage?: number
  defaultEmployeeContributionPercentage?: number
  salarySacrificeMode: GroupSchemeSalarySacrificeMode
  employerPensionSchemeReference: string
  isAutoEnrolment: boolean
  defaultPayrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  defaultContributionFrequency: GroupSchemeEnrolmentPayrollFrequency
  organizationId: string
  defaultGroupPortfolioId: string
  defaultEnrolmentName: string
  defaultTreatContributionsAsRegular: boolean
  previousPensionProviderBrandKey?: string
  previousPensionProviderBrandName?: string
  enabledRequestTypes?: GroupSchemeRequestType[]
  defaultContributionMethod?: PaymentMethod
  schemeOptions?: GroupSchemeOption[]
}

export interface UpdateGroupSchemeDto {
  id: string
  name?: string
  status?: GroupSchemeStatus
  tprLetterCode?: string
  dutiesStartDate?: string
  defaultEmployerContributionPercentage?: number
  defaultEmployeeContributionPercentage?: number
  salarySacrificeMode?: GroupSchemeSalarySacrificeMode
  isAutoEnrolment?: boolean
  defaultPayrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  defaultContributionFrequency?: GroupSchemeEnrolmentPayrollFrequency
  defaultGroupPortfolioId?: string
  defaultEnrolmentName?: string
  defaultTreatContributionsAsRegular?: boolean
  previousPensionProviderBrandKey?: string
  previousPensionProviderBrandName?: string
  enabledRequestTypes?: GroupSchemeRequestType[]
  defaultContributionMethod?: PaymentMethod
  schemeOptions?: GroupSchemeOption[]
}

export enum GroupSchemeSearchStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ANY = 'Any',
}

export enum GroupSchemeJobType {
  MEMBER = 'Member',
  CONTRIBUTION = 'Contribution',
}

export enum GroupSchemeJobDataSetFormat {
  PAPDIS = 'PAPDIS',
  JSON = 'JSON',
}

export enum GroupSchemeJobSearchType {
  ANY = 'Any',
  MEMBER = 'Member',
  CONTRIBUTION = 'Contribution',
}

export enum GroupSchemeJobSearchDataSetFormat {
  ANY = 'Any',
  PAPDIS = 'PAPDIS',
  JSON = 'JSON',
}

export enum GroupSchemeJobStatus {
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  INVESTIGATING = 'Investigating',
  COMPLETED = 'Completed',
  COMPLETED_WITH_ERRORS = 'Completed With Errors',
  REJECTED = 'Rejected',
}

export enum GroupSchemeJobSearchStatus {
  ANY = 'Any',
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
  COMPLETED_WITH_ERRORS = 'Completed With Errors',
  REJECTED = 'Rejected',
}

export interface GroupSchemeJobFilterDto {
  groupSchemeId: string
  search?: string
  jobType?: GroupSchemeJobType
  dataSetFormat?: GroupSchemeJobDataSetFormat
  status?: GroupSchemeJobSearchStatus
  resultAcknowledged?: boolean | string
}

export interface GroupSchemeJobDto {
  id: string
  status: GroupSchemeJobStatus
  jobType: GroupSchemeJobType
  dataSetFormat: GroupSchemeJobDataSetFormat
  groupSchemeId: string
  errorMessage?: string
  supportMessage?: string
  originalFilename?: string
  resultAcknowledged: boolean
  fileDownloadUrl?: string
  failedRecordFileDownloadUrl?: string
  recordCount?: number
  failedRecords?: GroupSchemeJobRecordDto[]
  paymentId?: string
  paymentAmount?: number
  paymentReference?: string
  createdAt: string
  expectedCompleteAt?: string
  updatedAt: string
  creator?: SimpleUserDto
  actioner?: SimpleUserDto
  acknowledger?: SimpleUserDto
  description?: string
}

export enum GroupSchemeJobRecordStatus {
  FAILED = 'Failed',
  COMPLETED = 'Completed',
}

export interface GroupSchemeJobRecordDto {
  id: string
  status: GroupSchemeJobRecordStatus
  lineNumber: number
  statusReason: string
  actioned: boolean
  referenceDetails: GroupSchemeJobRecordReferenceDetailsDto
}

export interface GroupSchemeJobRecordReferenceDetailsDto {
  nationalInsuranceNo?: string
  firstName?: string
  surname?: string
  employerContributionAmount?: number
  employeeContributionAmount?: number
  employeeId?: string
  employeeEmail?: string
}

export interface CreateGroupSchemeJobDto {
  groupSchemeId: string
  jobType: GroupSchemeJobType
  dataSetFormat: GroupSchemeJobDataSetFormat
  memberRecords?: CreateGroupSchemeJobRecordMemberDto[]
  contributionRecords?: CreateGroupSchemeJobRecordContributionDto[]
}

export interface CreateGroupSchemeJobRecordMemberDto {
  nationalInsuranceNo: string
  employeeEmail: string
  employeeId?: string
  title?: Title
  firstName: string
  surname: string
  birthDate: string
  gender: Gender
  addressDetail: Address
  employmentStartDate?: string
  enrolmentDate?: string
  enrolmentEndDate?: string
  enrolmentEndReason?: GroupSchemeEnrolmentEnrolmentEndReason
  autoEnrolmentOptOutDate?: string
  autoEnrolmentWindowOptOutDate?: string
  memberId?: string
}

export interface CreateGroupSchemeJobRecordContributionDto {
  nationalInsuranceNo: string
  employeeEmail?: string
  employeeId?: string
  title?: Title
  firstName: string
  surname: string
  birthDate?: string
  gender?: Gender
  payPeriodStartDate?: string
  payPeriodEndDate?: string
  employerContributionAmount: number
  employeeContributionAmount: number
  pensionableEarningsAmount?: number
  employerContributionPercent?: number
  employeeContributionPercent?: number
  salarySacrificeIndicator?: boolean
  payrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  memberId?: string
}

export enum GroupSchemePaymentStatus {
  AWAITING_CONFIRMATION = 'Awaiting Confirmation',
  AWAITING_PAYMENT = 'Awaiting Payment',
  PAID = 'Paid',
  DECLARED = 'Declared Paid',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum GroupSchemePaymentCollectionStatus {
  NOT_REQUIRED = 'Not Required',
  AWAITING_COLLECTION = 'Awaiting Collection',
  COMPLETED = 'Completed',
  FAILED_INSUFFICIENT_FUNDS = 'Insufficient Funds',
  FAILED_NOT_AUTHORISED = 'Not Authorised',
}

export enum GroupSchemePaymentSearchStatus {
  ANY = 'Any',
  AWAITING_PAYMENT = 'Awaiting Payment',
  PAID = 'Paid',
  DECLARED = 'Declared Paid',
  COMPLETED = 'Completed',
}

export interface GroupSchemePaymentDto {
  id: string
  reference: string
  amount: number
  groupSchemeJobId: string
  status: GroupSchemePaymentStatus
  paidAt?: string
  createdAt: string
  updatedAt: string
  payer?: SimpleUserDto
  confirmer?: SimpleUserDto
  expectedCollectionAt?: string
  expectedCompleteAt?: string
  contributionMethod: PaymentMethod
  collectionStatus: GroupSchemePaymentCollectionStatus
}

export interface GroupSchemeJobCheckStatusDto {
  groupSchemeId: string
  jobId: string
  currentStatus: GroupSchemeJobStatus
}

export interface GroupSchemeJobAcknowledgeDto {
  groupSchemeId: string
  jobId: string
}

export class GroupSchemeJobSetActionedRecordsDto {
  groupSchemeId: string
  jobId: string
  actionedRecordIds: string[]
}

export interface GroupSchemeRequestFilterDto {
  groupSchemeId: string
  search?: string
  requestType?: GroupSchemeRequestType
  status?: GroupSchemeRequestStatus
}

export interface GroupSchemeMemberDto {
  type: string
  id: string
  title: Title
  firstName: string
  surname: string
  gender: Gender
  birthDate: string
  nationalInsuranceNo: string
  addressDetail: Address
  isVerified: boolean
  enrolmentStatus: GroupSchemeEnrolmentStatus
  groupSchemeId?: string
  employeeId?: string
  employeeEmail?: string
  employmentStartDate?: string
  autoEnrolmentWindowOptOutDate?: string
  autoEnrolmentOptOutDate?: string
  enrolmentDate?: string
  enrolmentEndDate?: string
  enrolmentEndReason?: GroupSchemeEnrolmentEnrolmentEndReason
  lastEmployerContributionAmount: number
  lastEmployeeContributionAmount: number
}

export interface GroupSchemeMemberFilterDto {
  groupSchemeId: string
  search?: string
  employeeId?: string
  nationalInsuranceNo?: string
  enrolmentStatus?: GroupSchemeEnrolmentStatus
}

export class GroupSchemePaymentFilterDto {
  groupSchemeId: string
  status?: GroupSchemePaymentStatus
  search?: string
  incomplete?: boolean
}

export interface GroupSchemePaymentConfirmDto {
  groupSchemeId: string
  paymentId: string
}

export interface GroupSchemePaymentCancelDto {
  groupSchemeId: string
  paymentId: string
}

export class GroupSchemePaymentExecuteDto {
  groupSchemeId: string
  paymentId: string
  contributionMethod: PaymentMethod
}

export interface GroupSchemeRequestDto {
  type: string
  id: string
  requestType: GroupSchemeRequestType
  status: GroupSchemeRequestStatus
  data: any
  actionDate?: string
  actionMessage?: string
  enrolment: GroupSchemeRequestEnrolmentDto
  client: GroupSchemeRequestClientDto
  createdAt: string
  updatedAt: string
  actioner?: SimpleUserDto
}

export interface GroupSchemeRequestClientDto {
  title: Title
  firstName: string
  surname: string
  gender: Gender
  birthDate: string
  nationalInsuranceNo: string
}

export interface GroupSchemeRequestEnrolmentDto {
  enrolmentId: string
  employeeId?: string
  employeeEmail?: string
}

export interface UpdateGroupSchemeRequestDto {
  groupSchemeId: string
  requestId: string
  status: GroupSchemeRequestStatus
  actionMessage?: string
}

export class CreateGroupSchemeInviteDto {
  groupSchemeId: string
  email: string
  nationalInsuranceNo?: string
  firstName: string
  surname: string
  title: Title
  gender: Gender
  birthDate: string
}

export interface GroupSchemeInviteDto {
  type: string
  id: string
  createdAt: string
  updatedAt: string
  groupSchemeId: string
  email: string
  nationalInsuranceNo: string
  firstName: string
  surname: string
  title: Title
  gender: Gender
  birthDate: string
  status: InviteStatus
}

export interface PublicGroupSchemeInviteDto {
  type: string
  id: string
  createdAt: string
  updatedAt: string
  email: string
  status: InviteStatus
  groupScheme: PublicGroupSchemeDto
  metadata: UserMetadata
}

export interface DeclineGroupSchemeInviteDto {
  groupSchemeId: string
  inviteId: string
}

export interface AcceptGroupSchemeInviteDto {
  groupSchemeId: string
  inviteId: string
  nationalInsuranceNo: string
}

export interface PublicGroupSchemeDto {
  id: string
  organizationName: string
  organizationDisplayName: string
  schemeName: string
  logo: string
  companyNo: string
  primaryContactUser: SimpleUserDto
  defaultContributionFrequency: GroupSchemeEnrolmentPayrollFrequency
  enabledRequestTypes?: GroupSchemeRequestType[]
  previousPensionProviderBrandKey?: string
  previousPensionProviderBrandName?: string
}

export interface GroupSchemeInviteFilterDto {
  groupSchemeId: string
  search?: string
  status?: InviteSearchStatus
  accepted?: boolean | string
  nationalInsuranceNo?: string
}

export interface GroupSchemeJobsBulkAcknowledgeDto {
  groupSchemeId: string
  jobIds: string[]
}

export interface BulkCreateGroupSchemeInviteOutputDto {
  create: number
  error: number
  details: BulkCreateGroupSchemeInviteOutputDetailDto[]
}

export interface BulkCreateGroupSchemeInviteOutputDetailDto {
  csvLine: number
  action: 'create' | 'error'
  nationalInsuranceNo?: string
  email?: string
  errorMessage?: string
}

export enum OverrideGroupOrganizationPaymentMethod {
  USE_PARENT = 'Use Organization Configuration',
  DIRECT_DEBIT = 'Direct Debit',
  BANK_TRANSFER = 'Bank Transfer',
}

export interface GroupSchemeUserRoleDto {
  userId: string
  schemeRole: GroupSchemeRole
  schemeRoleIsForOrganization?: boolean
}

export interface GroupSchemeUserRolesDto {
  type: string
  userRoles: GroupSchemeUserRoleDto[]
}

export interface SetGroupSchemeUserRoleDto {
  userId: string
  schemeRole: GroupSchemeRole
}

export interface SetGroupSchemeUserRolesDto {
  schemeId: string
  organizationId: string
  userRoles: SetGroupSchemeUserRoleDto[]
}