import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { FooterInfo } from 'components/Utility/FooterInfo'
import { Loading } from 'components/Utility/Loading'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import React, { useEffect, useRef } from 'react'
import { KeyboardAvoidingView, ScrollView, StyleProp, View, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { ModalEditHeader } from './ModalEditHeader'
import { useBeforeunload } from 'react-beforeunload'

export type ModalEditScreenButtonProps = {
  buttonTitle: string
  buttonAction: any
  footerInfo?: React.ReactNode
  showButton?: boolean
  enableButton?: boolean
  allowTextButton?: boolean
  textButtonTitle?: string
  textButtonAction?: any
  enableTextButton?: boolean
 }

export type ModalEditScreenProps = ModalEditScreenButtonProps & {
  children?: any
  error?: any
  errorTryAgain?: any
  errorCancel?: any
  isLoading?: boolean
  loadingMessage?: string[]
  containerStyle?: StyleProp<ViewStyle>
  formTitle: string
  onDismiss: Function
  dismissDialogText?: string
  isDirty?: boolean
  onDelete?: Function
  deleteDialogText?: string
}

const isWeb = platformIsWeb()

export const ModalEditScreen = (props: ModalEditScreenProps) => {

  const { children, error, isLoading, loadingMessage, buttonTitle, buttonAction, showButton, enableButton, errorTryAgain, errorCancel, allowTextButton, textButtonAction, textButtonTitle, enableTextButton, containerStyle, footerInfo, formTitle, onDismiss, dismissDialogText, onDelete, deleteDialogText, isDirty } = props

  if (isWeb) {
    useBeforeunload(() => isDirty ? dismissDialogText || 'You may lose data!' : false);
  }

  //Calculate the keyboard offset
  const insets = useSafeAreaInsets()
  const headerHeight = Sizing.x110 //See layout.ts modalHeaderContainer style
  const processScreenContainerPadding = Sizing.x0 //No top padding above KeyboardAvoidingView
  const keyboardVerticalOffset = headerHeight + processScreenContainerPadding + insets.top

  const { colors: themeColors } = Paper.useAppTheme()

  const buttonDisabled = enableButton === false
  const textButtonDisabled = enableTextButton === false

  const viewConstraints: any = getScreenWidthConstraints()

  const ready = !isLoading && !error

  //Flash scroll bars on entry
  const scrollRef = useRef<ScrollView>(null)
  useEffect(() => {
    scrollRef.current?.flashScrollIndicators()
  }, [])

  return (
    <>
          <View style={[
            {
            ...Flex.column.start,
            },
            viewConstraints,
          ]}>
            <ModalEditHeader
              formTitle={formTitle}
              onDismiss={onDismiss}
              isDirty={isDirty}
              dismissDialogText={dismissDialogText}
              onDelete={ready ? onDelete : undefined}
              deleteDialogText={deleteDialogText}
            />
            <View style={[
              layoutStyles.mainContentContainer,
              containerStyle ? containerStyle : {},
            ]}>
              {
                //Error/loading
                error || isLoading ?
                  <View style={{
                    ...Flex.column.start,
                    paddingHorizontal: Sizing.x30,
                  }}>
                    <View style={{ ...Flex.override.fill }}>
                      {
                        error ? <ErrorScreen
                          errorTryAgain={errorTryAgain}
                          errorCancel={errorCancel}
                          error={error?.data}
                          noDashboardButton={true}
                          />
                        : isLoading ? <Loading message={loadingMessage} useHolidayGraphics={true} /> : <></>
                      }
                    </View>
                    <View style={{ height: Sizing.x90 }}></View>
                  </View>
        
                  :
        
                  <View style={{
                    ...Flex.column.start,
                    // backgroundColor: 'yellow',
                  }}>
                    {/* <Paragraph>Helo</Paragraph> */}
                    <View style={{
                      ...Flex.override.fill,
                      paddingHorizontal: Sizing.x30,
                      // backgroundColor: 'green',
                    }}>
                      <KeyboardAvoidingView
                        style={{...Flex.override.fill }}
                        behavior={'padding'}
                        keyboardVerticalOffset={keyboardVerticalOffset}
                      >
                        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
                          <ScrollView
                            ref={scrollRef}
                            nestedScrollEnabled
                            style={layoutStyles.scrollContainer}
                            contentContainerStyle={layoutStyles.scrollContainerContent}
                            keyboardShouldPersistTaps='handled'
                            showsVerticalScrollIndicator={true}
                          >
                            <View style={{
                              ...Flex.column.start,
                              ...Flex.override.fill,
                            }}>
                              <View style={{
                                flex: 1,
                              }}>
                                {children}
                              </View>
                            </View>
                          </ScrollView>
                        {/* </TouchableWithoutFeedback> */}
                      </KeyboardAvoidingView>
                    </View>
                    <View style={{ ...Flex.override.bottom }}>
                    { footerInfo ?
                        <FooterInfo>
                          {footerInfo}
                        </FooterInfo>
                        : <></>
                      }
                    </View>
                    <View style={{ ...Flex.override.bottom }}>
                      {allowTextButton ? <FooterButton
                        mode='text'
                        onPress={textButtonAction}
                        disabled={textButtonDisabled}
                      >{textButtonTitle}</FooterButton> : <></>}
                      {showButton === undefined || showButton ?
                        <FooterButton
                          onPress={buttonAction}
                          disabled={buttonDisabled}
                        >{buttonTitle}</FooterButton>
        
                        : <></>
                      }
                    </View>
                  </View>
              }
            </View>
          </View>
    </>
  )
}
