import { GroupSchemeRole } from 'store/dto/group-organization.dto'
import { UserGroupSchemeDto } from 'store/dto/group-scheme.dto'

export const userHasRequiredRole = (userScheme: UserGroupSchemeDto, requiredRoles: GroupSchemeRole[]) => {
  const { userSchemeRole } = userScheme || {}
  return requiredRoles.includes(userSchemeRole)
}

export const userCanManageJobsForScheme = (userScheme: UserGroupSchemeDto): boolean => {
  return userHasRequiredRole(userScheme, [
    GroupSchemeRole.PAYROLL_ASSISTANT,
    GroupSchemeRole.PAYROLL_MANAGER,
    GroupSchemeRole.SCHEME_MANAGER,
  ])
}

export const userCanRectifyJobsForScheme = (userScheme: UserGroupSchemeDto): boolean => {
  return userHasRequiredRole(userScheme, [
    GroupSchemeRole.PAYROLL_MANAGER,
    GroupSchemeRole.SCHEME_MANAGER,
  ])
}

export const userCanManageRequestsForScheme = (userScheme: UserGroupSchemeDto): boolean => {
  return userHasRequiredRole(userScheme, [
    GroupSchemeRole.PAYROLL_MANAGER,
    GroupSchemeRole.SCHEME_MANAGER,
  ])
}

export const userCanManageInvitesForScheme = (userScheme: UserGroupSchemeDto): boolean => {
  return userHasRequiredRole(userScheme, [
    GroupSchemeRole.PAYROLL_MANAGER,
    GroupSchemeRole.SCHEME_MANAGER,
  ])
}

export const userCanConfirmPaymentsForScheme = (userScheme: UserGroupSchemeDto): boolean => {
  return userHasRequiredRole(userScheme, [
    GroupSchemeRole.PAYROLL_MANAGER,
    GroupSchemeRole.SCHEME_MANAGER,
  ])
}

export const userCanExecutePaymentsForScheme = (userScheme: UserGroupSchemeDto): boolean => {
  return userHasRequiredRole(userScheme, [
    GroupSchemeRole.FINANCE_MANAGER,
    GroupSchemeRole.SCHEME_MANAGER,
  ])
}
