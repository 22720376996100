import { useFocusEffect } from '@react-navigation/native'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { getDiagnosticInformation, getDiagnosticString, InformationSectionKey } from 'lib/appInfoHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect, useState } from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { AmlStatus, UpdateClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetup, workingContributionSetup } from 'store/tempDataSlice'
import { Colors } from 'styles'

export const MakeContributions_09_SetupSave = ({ route, navigation }) => {
  const { nextScreen, onDismiss, contributionsData }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingContributionData = useAppSelector(workingContributionSetup)

  const [verificationFailed, setVerificationFailed] = useState(false)

  useFocusEffect(() => {
    if (verificationFailed && workingContributionData === undefined) {
      setVerificationFailed(false)
    }
  })

  const [updateMe, { data: updatedMe, error, isLoading }] = useUpdateMeMutation()

  //Save on entry
  useEffect(() => {
    save()
  }, [])

  //Navigate on finish
  useEffect(() => {
    if (updatedMe) {
      //If we were trying to save a business contributionConfiguration and NOT pass
      //then show failed screen
      if (workingContributionData?.source === ContributionSource.EMPLOYER && updatedMe?.contributionBusinessAmlStatus !== AmlStatus.PASS) {
        setVerificationFailed(true)
      } else {
        ampli.contributionChangeSource({
          contributionSource: workingContributionData?.source,
        })
        next()
      }
    }
  }, [updatedMe, error])

  const save = () => {
    Logger.info(`Updating client contribution configuration...`)
    const source = workingContributionData?.source || contributionsData?.newContributionSource || ContributionSource.PERSONAL

    const payload: UpdateClientMeDto = source === ContributionSource.EMPLOYER
    ? {
        contributionConfiguration: {
          source,
          bankConnectionId: workingContributionData?.bankConnectionId,
          bankId: workingContributionData?.bankId,
          bankDetails: {
            ...workingContributionData?.bankDetails
          },
          employer: workingContributionData?.employer
        },
      }
    : {
        contributionConfiguration: {
          source,
          bankConnectionId: workingContributionData?.bankConnectionId,
          bankId: workingContributionData?.bankId,
          bankDetails: {
            ...workingContributionData?.bankDetails
          },
        },
      }

    updateMe(payload)
  }

  const next = () => {
    const newContributionConfiguration = updatedMe?.contributionConfiguration
    dispatch(updateWorkingContributionSetup(newContributionConfiguration))
    navigation.navigate(nextScreen)
  }

  const backToContributionSource = () => {
    //Clear and reset data
    dispatch(updateWorkingContributionSetup({
      source: ContributionSource.PERSONAL,
      bankId: undefined,
      bankDetails: undefined,
      employer: undefined,
    }))
    navigation.navigate('Bank')
  }

  const contactSupport = async () => {
    const informationArray = getDiagnosticInformation(undefined, updatedMe)
    const infoBody = getDiagnosticString(informationArray, [
      InformationSectionKey.DATE,
      InformationSectionKey.USER,
    ])
    let initialMessage = `----- Jarvis Limited Company Contributions -----\n\n`
    initialMessage += infoBody
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Client limited company verification required.\n`
    initialMessage += `---------------------------------------\n`
    await composeIntercomMessage(initialMessage)
  }

  return (
    <ModalProcessScreen
      headline={verificationFailed ? `Sorry, we cannot accept contributions` : undefined}
      subHeading={verificationFailed ? `We were unable to approve your limited company to make contributions` : undefined}
      error={error}
      errorTryAgain={save}
      errorCancel={onDismiss}
      isLoading={isLoading}
      loadingMessage={['Saving contribution source...']}
      buttonTitle={verificationFailed ? 'Contribute Personally' : ''}
      buttonAction={verificationFailed ? backToContributionSource : () => {} }
      showButton={verificationFailed}
      allowTextButton={verificationFailed}
      textButtonTitle={verificationFailed ? 'Cancel Contribution Source Setup' : undefined}
      textButtonAction={verificationFailed ? onDismiss : undefined}
    >
    {
      verificationFailed
        ? <>
            <Paragraph>{`You may still set up contributions from your personal account.`}</Paragraph>
            <Paragraph>{`Please contact support for more information.`}</Paragraph>
            <Button
              mode={'text'}
              color={Colors.brand.purple2}
              onPress={contactSupport}
            >
              {'Contact Support'}
            </Button>
          </>
        : <></>
    }
    </ModalProcessScreen>
  )
}

