import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { GroupSchemeMemberDto } from 'store/dto/group-scheme.dto'
import { Paper, Sizing } from 'styles'

type EmployerMemberListItemProp = {
  member: GroupSchemeMemberDto
  onPressFunction?: () => void
}
export const EmployerMemberListItem = (props: EmployerMemberListItemProp) => {
  const { member, onPressFunction } = props
  const { firstName, surname, nationalInsuranceNo, employeeId, employeeEmail, autoEnrolmentOptOutDate, enrolmentEndDate, enrolmentStatus, employmentStartDate, enrolmentDate } = member

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = 'account'
  const title = firstName && surname
    ? `${firstName} ${surname}`
    : firstName || surname || 'Unknown Name'
  const subTitle = `${employeeId} / ${nationalInsuranceNo} / ${employeeEmail}` 
  const titleRight = enrolmentStatus
  const titleRightStyle = {
    color: enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE ? themeColors.primary : themeColors.disabled
  }
  const subTitleRight =
    enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE
      ? enrolmentEndDate
        ? `Left scheme on: ${formatUkDate(new Date(enrolmentEndDate))}`
        : `Previously left scheme`
    : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT
      ? autoEnrolmentOptOutDate
        ? `Opted out on: ${formatUkDate(new Date(autoEnrolmentOptOutDate))}`
        : `Previously opted out`
    : enrolmentDate ? `Enrolled: ${formatUkDate(new Date(enrolmentDate))}`
    : employmentStartDate ? `Started: ${formatUkDate(new Date(employmentStartDate))}`
    : `Previously enrolled`

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      titleRight={titleRight}
      titleRightStyle={titleRightStyle}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{subTitleRight}</Text>}
    />
  )
}
