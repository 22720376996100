import { PaymentMethod } from './account.dto';
import { Address, BaseDatedDto, SimpleUserDto } from './base.dto';
import { BankDetails } from './client.dto';

export enum GroupOrganizationStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface GroupOrganizationDto extends BaseDatedDto {
  type: string
  id: string
  name: string
  displayName: string
  logo: string
  organizationType: string
  status: GroupOrganizationStatus
  companyNo: string
  payeReference: string
  taxReference: string
  registeredOfficeAddressDetail: Address
  tradingAddressDetail: Address
  advisorName: string
  advisorEmail: string
  affiliateId?: string
  bankConnectionId: string
  bankId?: string
  bankDetails: BankDetails
  apiKey?: string
  apiDisabled: boolean
  mandateStatus?: GroupOrganizationMandateStatus
  defaultContributionMethod: PaymentMethod
  primaryContactUser: SimpleUserDto
  users?: SimpleUserDto[]
}

export interface GroupOrganizationFilterDto {
  search?: string
  status?: GroupOrganizationStatus
}

export interface CreateGroupOrganizationDto {
  name: string
  displayName: string
  logo: string
  companyNo: string
  payeReference?: string
  taxReference?: string
  registeredOfficeAddressDetail: Address
  tradingAddressDetail: Address
  advisorName: string
  advisorEmail: string
  primaryContactFirstName: string
  primaryContactSurname: string
  primaryContactEmail: string
  affiliateId?: string
  bankConnectionId: string
  bankId?: string
  bankDetails: BankDetails
  generateApiKey?: string
  apiDisabled: boolean
  mandateStatus?: GroupOrganizationMandateStatus
}

export interface UpdateGroupOrganizationDto {
  id: string
  name?: string
  displayName?: string
  logo?: string
  status?: GroupOrganizationStatus
  payeReference?: string
  taxReference?: string
  affiliateId?: string
  apiDisabled?: boolean
  mandateStatus?: GroupOrganizationMandateStatus
  defaultContributionMethod?: PaymentMethod
}

export interface GroupOrganizationMandateDto {
  type: string
  id: string
  organizationId: string
  status: GroupOrganizationMandateStatus
  contributionSortCode?: string
  contributionAccountLast4?: string
  url?: string
  setupUrl?: string
}

export interface CreateGroupOrganizationMandateDto {
  id: string
  sendEmail: boolean
}

export enum GroupOrganizationSearchStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ANY = 'Any',
}

export enum GroupOrganizationMandateStatus {
  REQUESTED = 'Requested',
  NOT_REQUESTED = 'Not Requested',
  NOT_REQUIRED = 'Not Required',
  AWAITING_AUTH = 'Awaiting Authorisation',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum GroupOrganizationRole {
  NONE = '(None)', //Local frontend definition only
  ORG_ADMIN = 'Organisation Admin',
}

export enum GroupSchemeRole {
  NONE = '(None)', //Local frontend definition only
  RESTRICTED = 'Restricted',
  PAYROLL_ASSISTANT = 'Payroll Assistant',
  PAYROLL_MANAGER = 'Payroll Manager',
  FINANCE_MANAGER = 'Finance Manager',
  SCHEME_MANAGER = 'Scheme Manager',
}

export interface GroupOrganizationUserRoleDto {
  userId: string
  organizationRole: GroupOrganizationRole
  schemeRole: GroupSchemeRole
}

export interface GroupOrganizationUserRolesDto {
  type: string
  userRoles: GroupOrganizationUserRoleDto[]
}

export interface SetGroupOrganizationUserRoleDto {
  userId: string
  organizationRole: GroupOrganizationRole
  schemeRole: GroupSchemeRole
}

export interface SetGroupOrganizationUserRolesDto {
  organizationId: string
  userRoles: SetGroupOrganizationUserRoleDto[]
}

export interface AddGroupOrganizationUserDto {
  organizationId: string
  firstName: string
  surname: string
  email: string
}