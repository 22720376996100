import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Subheading } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { orderBy } from 'lodash'
import { default as React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupOrganizationUserMutation, useGetGroupOrganizationQuery, useGetGroupOrganizationUserRolesQuery, useGetGroupOrganizationUsersQuery, useSetGroupOrganizationUserRolesMutation } from 'store/apiSlice'
import { SimpleUserDto } from 'store/dto/base.dto'
import { GroupOrganizationRole, GroupSchemeRole, SetGroupOrganizationUserRoleDto } from 'store/dto/group-organization.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupOrganizationSetRolesDataId, setCurrentAdminGroupOrganizationDataId, setCurrentAdminGroupOrganizationSetRolesDataId } from 'store/uxSlice'
import { Paper } from 'styles'
import { AdminGroupOrganizationUserRolesTable } from './Components/AdminGroupOrganizationUserRolesTable'

export type GroupOrganizationUserRoleAssertion = {
  userId: string
  userName: string
  userEmail: string
  schemeRole: GroupSchemeRole
  organizationRole: GroupOrganizationRole
}

export const AdminGroupOrganizationSetUserRolesModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {

  const dispatch = useAppDispatch()

  const currentGroupOrganizationId = useAppSelector(currentAdminGroupOrganizationSetRolesDataId)

  const [saveDialogVisible, setSaveDialogVisible] = useState(false)
  const [discardDialogVisible, setDiscardDialogVisible] = useState(false)

  const { data: groupOrganization, isLoading: groupOrganizationIsLoading, error: groupOrganizationError, refetch: refetchOrganizations } = useGetGroupOrganizationQuery(currentGroupOrganizationId, { skip: !currentGroupOrganizationId } )
  const { data: groupOrgUsers, isLoading: groupOrgUsersIsLoading, error: groupOrgUsersError, refetch: refetchOrgUsers } = useGetGroupOrganizationUsersQuery(currentGroupOrganizationId, { skip: !currentGroupOrganizationId } )
  const { data: roles, isLoading: rolesIsLoading, error: rolesError, refetch: refetchRoles } = useGetGroupOrganizationUserRolesQuery(currentGroupOrganizationId, { skip: !currentGroupOrganizationId } )
  const [setUserRoles, { data: updatedUserRoles, isLoading: userRoleUpdateIsLoading, error: userRoleUpdateError }] = useSetGroupOrganizationUserRolesMutation()

  const mapUsersToGroupOrganizationUserRoleAssertions = (): GroupOrganizationUserRoleAssertion[] => {
    const { userRoles } = roles || {}
    return groupOrgUsers && userRoles ? groupOrgUsers.map((user: SimpleUserDto): GroupOrganizationUserRoleAssertion => {
      const userRole = userRoles.find(ur => {
        return ur.userId === user.id
      })
      return {
        userId: user.id,
        userName: `${user.firstName} ${user.surname}`,
        userEmail: user.email,
        schemeRole: userRole?.schemeRole || GroupSchemeRole.NONE,
        organizationRole: userRole?.organizationRole || GroupOrganizationRole.NONE,
      }
    }) : []
  }

  const formObj = useForm<{
    userRoleAssertions: GroupOrganizationUserRoleAssertion[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, setValue, reset, setError, watch, trigger, formState: { isDirty, isValid } } = formObj

  useEffect(() => {
    if (groupOrgUsers && roles) {
      reset({
        userRoleAssertions: mapUsersToGroupOrganizationUserRoleAssertions()
      })
    }
  }, [groupOrgUsers, roles])

  const onSubmit = async attributes => {
    const {
      userRoleAssertions,
    } = attributes 

    const userRoles: SetGroupOrganizationUserRoleDto[] = userRoleAssertions
      .filter((ur: GroupOrganizationUserRoleAssertion) => {
        return ur.organizationRole !== GroupOrganizationRole.NONE || ur.schemeRole !== GroupSchemeRole.NONE
      })
      .map((ur: GroupOrganizationUserRoleAssertion): SetGroupOrganizationUserRoleDto => {
        return {
          userId: ur.userId,
          schemeRole: ur.schemeRole === GroupSchemeRole.NONE ? undefined : ur.schemeRole,
          organizationRole: ur.organizationRole === GroupOrganizationRole.NONE ? undefined : ur.organizationRole,
        }
      })

    await setUserRoles({
      organizationId: currentGroupOrganizationId,
      userRoles,
    })
    setSaveDialogVisible(false)
  }

  useEffect(() => {
    if (updatedUserRoles) {
      goToOrganizationView()
    }
  }, [updatedUserRoles])

  const isLoading = groupOrganizationIsLoading || groupOrgUsersIsLoading || rolesIsLoading || userRoleUpdateIsLoading
  const error: any = groupOrganizationError || groupOrgUsersError || rolesError || userRoleUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const goToOrganizationView = () => {
    dispatch(setCurrentAdminGroupOrganizationSetRolesDataId(undefined))
    dispatch(setCurrentAdminGroupOrganizationDataId(currentGroupOrganizationId))
  }

  return (
    <ModalEditScreen
      formTitle={'Edit User Roles'}
      onDismiss={goToOrganizationView}
      isDirty={isDirty}
      dismissDialogText={'Discard role changes?'}
      error={error}
      errorCancel={goToOrganizationView}
      errorTryAgain={groupOrganizationError ? refetchOrganizations
        : groupOrgUsersError ? refetchOrgUsers
        : rolesError ? refetchRoles
        : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={userRoleUpdateIsLoading ? ['Saving user roles...'] : undefined}
      buttonTitle={'Save Changes'}
      buttonAction={() => setSaveDialogVisible(true)}
      showButton={true}
      allowTextButton={true}
      textButtonTitle={'Return to Organization View'}
      textButtonAction={isDirty ? () => setDiscardDialogVisible(true) : goToOrganizationView}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Organisation Details`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupOrganization?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: groupOrganization?.name,
          },
          {
            label: `Company No`,
            value: groupOrganization?.companyNo,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />
      <ContentDivider />

      <AdminGroupOrganizationUserRolesTable
        groupOrganization={groupOrganization}
        formObj={formObj}
        name={'userRoleAssertions'}
      />

      <ConfirmationDialog
        visible={saveDialogVisible}
        title={'Are you sure?'}
        content={`Save changes to user roles for organisation?`}
        confirmLabel={'Save'}
        onCancel={() => setSaveDialogVisible(false)}
        onConfirm={handleSubmit(onSubmit)}
      />
      <ConfirmationDialog
        visible={discardDialogVisible}
        onCancel={() => setDiscardDialogVisible(false)}
        title={'Are you sure?'}
        content={`Discard changes and return to organization view?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Discard Changes'}
        onConfirm={goToOrganizationView}
      />
    </ModalEditScreen>
  )
}


