import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { formatSortCode } from 'lib/clientHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useState } from 'react'
import 'react-native-get-random-values'; //this must always be before importing uuid
import { useGetBankConnectionsQuery, useGetContributionBankAccountQuery } from 'store/apiSlice'
import { useAppSelector } from 'store/hooks'
import { workingContributionPayment, workingContributionSetup } from 'store/tempDataSlice'
import { Sizing, Typography } from 'styles'

export const MakeContributions_26_RegularManualSetup = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}

  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()
  const { data: payee, error: payeeError, isLoading: payeeIsLoading, refetch: refetchPayee } = useGetContributionBankAccountQuery()

  const isLoading = banksIsLoading || payeeIsLoading
  const error: any = banksError || payeeError
  const workingContributionPaymentData = useAppSelector(workingContributionPayment)
  
  const [showDialog, setShowDialog] = useState(false)

  const { amount, firstPaymentDate } = workingContributionPaymentData || {}

  const workingContributionData = useAppSelector(workingContributionSetup)
  const contributionConfiguration = contributionsData?.existingContributionConfiguration || workingContributionData

  const contributionBank = banks ? banks.find(bank => {
    return bank.id === contributionConfiguration?.bankConnectionId
  }) : undefined

  const onSubmit = () => {
    setShowDialog(false)
    navigation.navigate(nextScreen)
  }

  const standingOrderData: UnborderedTableRow[] = [
    {
      label: `Payee Type`,
      value: `Business`,
    },
    {
      label: `Payee Name`,
      value: `Seccl Custody`,
      copyableValue: `Seccl Custody`,
    },
    {
      label: `Sort Code`,
      value: formatSortCode(payee?.sortCode),
      copyableValue: payee?.sortCode,
    },
    {
      label: `Account Number`,
      value: payee?.accountNumber,
      copyableValue: payee?.accountNumber,
    },
    {
      label: `Amount`,
      value: formatCurrencyAmount(amount, 2),
    },
    {
      label: `Reference`,
      value: contributionConfiguration?.paymentReference,
      copyableValue: contributionConfiguration?.paymentReference,
    },
    {
      label: `First Payment`,
      value: firstPaymentDate ? format(new Date(firstPaymentDate), 'do MMMM') : '',
    },
    {
      label: `Frequency`,
      value: `Monthly`,
    },
    {
      label: `Until`,
      value: `Further Notice`,
    },
  ]

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={banksError ? refetchBanks : refetchPayee}
      errorCancel={onDismiss}
      isLoading={isLoading}
      loadingMessage={['Getting payment details...']}
      buttonTitle={`Confirm`}
      buttonAction={() => setShowDialog(true)}
      enableButton={true}
      headline={`Please create a standing order in your banking app`}
      showButton={true}
    >
      {
        workingContributionPaymentData ?
        <>
          <Paragraph style={{textAlign: 'left'}}>
            {`Use the following details to originate from your `}
            <Paragraph style={Typography.fontWeight.bold}>{contributionBank?.name}</Paragraph>
            {` account `}
            <Paragraph style={Typography.fontWeight.bold}>{formatSortCode(contributionConfiguration?.bankDetails?.sortCode)}</Paragraph>
            {` `}
            <Paragraph style={Typography.fontWeight.bold}>{contributionConfiguration?.bankDetails?.accountNumber}</Paragraph>
            {`.`}
          </Paragraph>
          <NamedInformationButton name={NamedInformation.MANUAL_STANDING_ORDER} buttonTitle={'How do I do this?'} />
          <UnborderedTable
            itemContainerStyle={{
              paddingVertical: Sizing.x5,
            }}
            data={standingOrderData?.map(item => {
              return {
                ...item,
                value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
              }
            })}
          />
        </>
        : <></>
      }
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'All done?'}
        content={`Please confirm you have created the standing order with your bank to exactly match the details shown here.`}
        onConfirm={onSubmit}
        cancelLabel={`Go back`}
        confirmLabel={`Yes, I've done it`}
      />
    </ModalProcessScreen>
  )
}
