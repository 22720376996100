import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { AutoCompleteItem } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatISO } from 'date-fns'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { mapMemberToEnrolmentJobMember } from 'lib/enrolmentHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { employerAppNavigate } from 'lib/RootNavigation'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMemberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentEnrolmentEndReason, GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { CreateGroupSchemeJobDto, GroupSchemeJobDataSetFormat, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerMemberDataId, setShowEmployerChangeEnrolmentStatusJobDataId, setSnackbarData, showEmployerChangeEnrolmentStatusJobDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerChangeEnrolmentStatusJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {

    const dispatch = useAppDispatch()

    const [showDialog, setShowDialog] = useState(false)

    const currentScheme = useAppSelector(currentGroupScheme)

    const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
    
    const currentMemberId = useAppSelector(showEmployerChangeEnrolmentStatusJobDataId)
  
    const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )
  
    const { firstName, surname, title, gender, birthDate, nationalInsuranceNo, addressDetail } = member || {}
  
    const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError }] = useAddGroupSchemeJobWithDataMutation()
    
    const formObj = useForm<{
      enrolmentStatus: GroupSchemeEnrolmentStatus
      enrolmentEndDate?: string
      enrolmentEndReason?: GroupSchemeEnrolmentEnrolmentEndReason
      autoEnrolmentOptOutDate?: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
    const { handleSubmit, setValue, setError, reset, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    useEffect(() => {
      if (member) {
        reset({
          enrolmentStatus: member?.enrolmentStatus,
          enrolmentEndDate: member?.enrolmentEndDate || formatISO(new Date()),
          enrolmentEndReason: member?.enrolmentEndReason,
          autoEnrolmentOptOutDate: member?.autoEnrolmentOptOutDate || formatISO(new Date()),
        })
      }
    }, [member])
  
    //Form refs for focussing
    const enrolmentEndDateRef = useRef(null)
    const autoEnrolmentOptOutDateRef = useRef(null)
  
    const onSubmit = async attributes => {
      const { enrolmentStatus, enrolmentEndDate, enrolmentEndReason, autoEnrolmentOptOutDate } = attributes
      const memberUpdate = {
        ...member,
        enrolmentStatus,
        enrolmentEndDate,
        enrolmentEndReason,
        autoEnrolmentOptOutDate,
      }
      const data: CreateGroupSchemeJobDto = {
        groupSchemeId: currentScheme.id,
        jobType: GroupSchemeJobType.MEMBER,
        dataSetFormat: GroupSchemeJobDataSetFormat.JSON,
        memberRecords: [mapMemberToEnrolmentJobMember(memberUpdate)]
      }
      addMemberJob(data)
    }
    
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Member Status Change Enrolment Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        closeAndGoToJobs()
      }
    }, [addedJob])
  
    const close = () => {
      dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(undefined))
    }

    const goToMemberView = () => {
      dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(undefined))
      dispatch(setCurrentEmployerMemberDataId(currentMemberId))
    }
  
    const closeAndGoToJobs = () => {
      employerAppNavigate(EmployerAppNavScreen.ENROLMENTS)
      close()
    }
  
    const isLoading = memberIsLoading || addJobIsLoading
    const error: any = memberError || addJobError
  
    const { colors: themeColors } = Paper.useAppTheme()
    const enrolmentStatus = watch('enrolmentStatus')
  
    const statusOptions: AutoCompleteItem[] = [
      {
        value: GroupSchemeEnrolmentStatus.ACTIVE,
        label: 'Active',
        description: 'Enrolled / Re-enrolled',
      },
      {
        value: GroupSchemeEnrolmentStatus.INACTIVE,
        label: 'Inactive',
        description: 'Ceased Enrolment',
      },
      {
        value: GroupSchemeEnrolmentStatus.OPTED_OUT,
        label: 'Opted Out',
        description: 'Opted Out Within Opt Out Window'
      },
    ]
  
    return (
      <ModalEditScreen
        formTitle={'Change Member Status'}
        onDismiss={() => dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(undefined))}
        isDirty={isDirty}
        dismissDialogText={'Discard status changes for this member?'}
        error={error}
        errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMember}
        errorCancel={close}
        isLoading={isLoading}
        loadingMessage={['Creating enrolment job...']}
        buttonTitle={'Submit Change'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid && userCanManageJobs}
        allowTextButton={true}
        textButtonTitle={'Return to Member Details'}
        textButtonAction={isDirty ? () => setShowDialog(true) : goToMemberView}
        footerInfo={userCanManageJobs ? undefined : <Text>{`You do not have permissions to update enrolled member status.`}</Text>}
      >
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Member Details`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `Jarvis Identifier`,
              value: member?.id,
              copyableValue: member?.id,
            },
            {
              label: `National Insurance No`,
              value: formatNationalInsuranceNumber(nationalInsuranceNo),
              copyableValue: nationalInsuranceNo,
            },
            {
              label: `Name`,
              value: `${firstName} ${member?.surname}`,
            },
            {
              label: `Gender`,
              value: gender,
            },
            {
              label: `Date of Birth`,
              value: birthDate ? formatUkDate(new Date(birthDate)) : '',
            },
          ]}
          noContentDivider={true}
        />
  
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{'Enrolment Status'}</Subheading>
        <ManagedAutoCompleteInput
          name={'enrolmentStatus'}
          formObj={formObj}
          label={'Status'}
          selectOnlyMode={true}
          dataSet={statusOptions}
          required={true}
        />
        <View style={{
          display: enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? undefined : 'none',
        }}>
          <ManagedDateInput
            ref={enrolmentEndDateRef}
            name={'enrolmentEndDate'}
            formObj={formObj}
            label={'Enrolment End Date'}
            blurOnSubmit={false}
            required={enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE}
          />
          <ManagedAutoCompleteInput
            name={'enrolmentEndReason'}
            formObj={formObj}
            label={'End Reason'}
            selectOnlyMode={true}
            dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentEnrolmentEndReason)}
            required={enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE}
          />
        </View>
        <View style={{
          display: enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT ? undefined : 'none',
        }}>
          <ManagedDateInput
            ref={autoEnrolmentOptOutDateRef}
            name={'autoEnrolmentOptOutDate'}
            formObj={formObj}
            label={'Opt Out Date'}
            blurOnSubmit={false}
            required={enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT}
          />
        </View>
        <ConfirmationDialog
          visible={showDialog}
          onCancel={() => setShowDialog(false)}
          title={'Are you sure?'}
          content={`Discard changes and return to member view?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Discard Changes'}
          onConfirm={goToMemberView}
        />
      </ModalEditScreen>
    )
  
}

