import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { pull, union } from 'lodash'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { Checkbox } from 'react-native-paper'
import { Typography, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'
import { ItemType } from 'react-native-dropdown-picker'
import { ContentDivider } from 'components/Layout/ContentDivider'

export type ManagedMultipleChoiceInputProps = {
  formObj: UseFormReturn<any>
  name: string
  options: ManagedMultipleChoiceInputOption[]
  submitHandler?: any
  noSeparator?: boolean
  disabled?: boolean
  informationMessage?: string
  informationMessageIsError?: boolean
}

export type ManagedMultipleChoiceInputOption = ItemType<any> & {
  linkUrl?: string
  linkIsDirect?: boolean
  linkIsPdf?: boolean
}

export const ManagedMultipleChoiceInput = (props:ManagedMultipleChoiceInputProps) => {
  const { formObj, submitHandler, options, name, noSeparator, disabled, informationMessage, informationMessageIsError } = props
  const { control } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
            <>
              {
                options.map((option, idx) => {
                  const isDisabled = disabled || option.disabled
                  const selected = value && value.includes(option.value)
                  return (
                    <View key={idx}>
                      <View style={{
                          flexDirection: option?.linkUrl ? 'row' : undefined,
                          justifyContent: option?.linkUrl ? 'flex-start' : undefined,
                      }}>
                        {
                          option?.linkUrl
                            ? <View style={{
                                flex: 1,
                                flexDirection: 'column',
                                justifyContent: 'center',
                              }}>
                                <ExternalLinkButton
                                  directToBrowser={option?.linkIsDirect}
                                  usePdfViewer={option?.linkIsPdf}
                                  url={option?.linkUrl}
                                  style={{
                                    alignSelf: 'flex-start'
                                  }}
                                >{option?.label}
                                </ExternalLinkButton>
                              </View>
                            : <></>
                        }
                        <View>
                          <Checkbox.Item
                            mode='android'
                            color={themeColors.accent}
                            uncheckedColor={isDisabled ? themeColors.disabled : themeColors.text}
                            labelStyle={{
                              ...Typography.defined.radioButtonLabel,
                              color: isDisabled ? themeColors.disabled : selected ? themeColors.accent : themeColors.text,
                            }}
                            // labelProps={{
                            //   adjustsFontSizeToFit: true,
                            //   numberOfLines: 1,
                            //   allowFontScaling: false,
                            // }}
                            status={selected ? 'checked' : 'unchecked'}
                            label={option?.linkUrl ? '' : option.label}
                            onPress={() => {
                              if (selected) {
                                onChange(pull(value, option.value))
                              } else {
                                onChange(union(value, [option.value]))
                              }
                              if(submitHandler) {
                                submitHandler()
                              }
                            }}
                            disabled={isDisabled}
                          />
                        </View>
                      </View>
                      { 
                        noSeparator ? <></> : <ContentDivider style={{ marginVertical: Sizing.x2, marginHorizontal: Sizing.x0 }} />
                      }
                    </View>
                  )
                })
              }
              <InputErrorMessages formObj={formObj} name={name} informationMessage={informationMessage} informationMessageIsError={informationMessageIsError} />
            </>
          )
        }
        name={name}
      />
    </View>
  )
}