import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddBankConnectionMutation } from 'store/apiSlice'
import { CreateBankConnectionDto, MoneyHubBankConnectionPaymentType } from 'store/dto/bank-connection.dto'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddBankConnectionVisible, showAdminAddBankConnectionVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddBankConnectionsModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    //Show required fields on load
    useEffect(() => {
      trigger()
    }, [])


    const dispatch = useAppDispatch()

    const [addBankConnectionMutation, { data: addedBankConnection, isLoading: addBankConnectionIsLoading, error: addBankConnectionError }] = useAddBankConnectionMutation()
  
    const formObj = useForm<CreateBankConnectionDto & {
      isOpenBankingEnabled: boolean,
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        contributionSources: [],
        paymentTypes: [],
        isBeta: false,
      },
    })
    //Form refs for focussing
    const moneyHubIdRef = useRef(null)
    const nameRef = useRef(null)
    const descriptionRef = useRef(null)
  
    const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
    register('logo', { required: true })
  
    const handleImagePick = (base64: string) => {
      setValue('logo', base64, { shouldDirty: true}) 
      trigger('logo')
    }
  
    const handleClearImage = () => {    
      setValue('logo', null, { shouldDirty: true})
    }
  
    const logo = watch('logo')
  
    const onSubmit = async attributes => {
      addBankConnectionMutation(attributes)
    }
  
    useEffect(() => {
      if (addedBankConnection) {
        close()
      }
    }, [addedBankConnection])
  
    const close = () => {
      dispatch(setShowAdminAddBankConnectionVisible(false))
    }
  
    const isLoading = addBankConnectionIsLoading
    const error: any = addBankConnectionError

    const isOpenBankingEnabledOptions: ManagedSimpleChoiceItem[] = [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ]
  
    const isBetaOptions: ManagedSimpleChoiceItem[] = [
      {
        value: false,
        label: 'No',
      },
      {
        value: true,
        label: 'Yes',
      },
    ]

    const isOpenBankingEnabled = watch('isOpenBankingEnabled')
  
    useEffect(() => {
      trigger('moneyHubId')
    }, [isOpenBankingEnabled])
  
    return (
      <ModalEditScreen
        formTitle='Add New Bank Connection'
        onDismiss={() => dispatch(setShowAdminAddBankConnectionVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard changes for this bank connection?'}
        error={error}
        errorTryAgain={addBankConnectionError ? handleSubmit(onSubmit) : undefined}
        errorCancel={close}
        isLoading={isLoading}
        loadingMessage={['Saving bank connection...']}
        buttonTitle={'Create Bank Connection'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <Subheading>{'Basic Details'}</Subheading>
        <ManagedTextInput
          ref={nameRef}
          name={'name'}
          formObj={formObj}
          label={'Name'}
          placeholder={'Name shown to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoFocus={true}
          submitHandler={() => descriptionRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 100,
        }}/>
        <ManagedTextInput
          ref={descriptionRef}
          name={'description'}
          formObj={formObj}
          label={'Description'}
          placeholder={'Optional description shown to users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 2,
            maxLength: 100,
        }}/>
        <View style={layoutStyles.inputContainer}>
          <ImagePickerButton
            disabled={!!logo}
            mode={'contained'}
            successHandler={handleImagePick}
            quality={1}
          >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
          {
            logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
          }
        </View>
        {
          logo ?
            <View style={{
              alignItems: 'center',
              paddingVertical: Sizing.x10,
            }}> 
              <View style={{
                borderRadius: Sizing.x5,
                borderStyle: 'dashed',
                borderColor: Colors.neutral.s400,
                borderWidth: Sizing.x2,
              }}>
                <Image
                  source={{ uri: logo }}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
            </View>
          : <></>
        }
        <Paragraph>{'Supported Contribution Sources'}</Paragraph>
        <ManagedMultipleChoiceInput
          formObj={formObj}
          name={'contributionSources'}
          options={enumToAutocompleteOptions(ContributionSource)}
        />

        <Subheading>{'Open Banking Configuration'}</Subheading>
        <Paragraph>{'Bank is supported by Open Banking/MoneyHub?'}</Paragraph>
        <ManagedSimpleChoiceInput
          name={'isOpenBankingEnabled'}
          formObj={formObj}
          options={isOpenBankingEnabledOptions}
          required={true}
        />
        {
          isOpenBankingEnabled
            ? <>
                <ManagedTextInput
                  ref={moneyHubIdRef}
                  name={'moneyHubId'}
                  formObj={formObj}
                  label={'MoneyHub ID'}
                  placeholder={'Identifier from MoneyHub'}
                  returnKeyType={'next'}
                  blurOnSubmit={false}
                  rules={{
                    required: isOpenBankingEnabled,
                    minLength: 32,
                    maxLength: 32,
                  }}/>
                <Paragraph>{'Supported Open Banking Payment Types'}</Paragraph>
                <ManagedMultipleChoiceInput
                  formObj={formObj}
                  name={'paymentTypes'}
                  options={enumToAutocompleteOptions(MoneyHubBankConnectionPaymentType)}
                />
                <Paragraph>{'Beta Status (not visible to clients)'}</Paragraph>
                <ManagedSimpleChoiceInput
                  name={'isBeta'}
                  formObj={formObj}
                  options={isBetaOptions}
                  required={isOpenBankingEnabled}
                />
              </>
            : <></>
        }
      </ModalEditScreen>
    )
  }
  
  
