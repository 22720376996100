import { ModalProcessScreen } from 'components/Layout'
import { concat, fill, floor, round } from 'lodash'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateBeneficiariesMutation } from 'store/apiSlice'
import { BeneficiaryRelationship, Nomination } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ManagedProportionInput } from 'components/Inputs/ManagedProportionInput'
import { ampli } from 'src/ampli'
import {
  adjustBeneficiaryProportionData,
  setAdjustBeneficiaryProportionData
} from 'store/uxSlice'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { View } from 'react-native'
import { Headline } from 'components/Typography'
import { Sizing } from 'styles'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'

export type AdjustProportionModalProps = {
}

export const AdjustProportionModal = (props: AdjustProportionModalProps) => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {

  const adjustProportion = useAppSelector(adjustBeneficiaryProportionData)
  const dispatch = useAppDispatch()

  const handleDismissScreen = () => {
    dispatch(setAdjustBeneficiaryProportionData(undefined))
  }
  
  const [updateBeneficiaries, { data: savedBeneficiaries, isLoading, error }] = useUpdateBeneficiariesMutation()
  const adjustBeneficiaryProportion = useAppSelector(adjustBeneficiaryProportionData)
  const { nominations } = adjustBeneficiaryProportion || {}

  //Create array of proportion values, adding zero for the new beneficiary
  const startingProportions = nominations ? nominations.map(nomination => {
    return nomination.beneficiaryProportion
  }) : []

  const getBeneficiaryName = (nomination: Partial<Nomination>) => {
    return nomination.beneficiaryRelationship === BeneficiaryRelationship.CHARITY
      ? nomination.firstName
      : `${nomination.firstName} ${nomination.surname}`
  }

  const labels = nominations ? nominations.map(nomination => {
    return getBeneficiaryName(nomination)
  }) : []

  const formObj = useForm<{ proportions: number[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      proportions: startingProportions,
    }
  })
  const { getValues, setValue, handleSubmit, trigger, watch, formState: { isDirty, isValid } } = formObj

  const equalizeValues = () => {
    const proportions = watch('proportions')
    const length = proportions.length
    const otherLength = length - 1
    if (otherLength < 1) {
      return
    }
    const equalShare = floor((1 / length), 2) // Nearest whole number
    const lastShare = 1 - (equalShare * otherLength)
    const newProportions = concat(fill(Array(otherLength), equalShare), [lastShare])
    setValue('proportions', newProportions )
    trigger('proportions')
  }

  const onSubmit = async attributes => {
    const updatedNominations = nominations.map((nomination, idx) => {
      return {
        ...nomination,
        beneficiaryProportion: round(attributes.proportions[idx], 2),
      }
    })

    updateBeneficiaries({ nominations: updatedNominations })
  }

  useEffect(() => {
    if (savedBeneficiaries) {
      const { nominations } = savedBeneficiaries
      ampli.beneficiariesChange({
        beneficiaryCount: nominations ? nominations.length : 0,
        beneficiaryRelationships: nominations ? nominations.map(nomination => {
          return nomination.beneficiaryRelationship
        }) : [],
        changeReason: 'proportions',
      })
      close()
    }
  }, [savedBeneficiaries])

  const close = () => {
    dispatch(setAdjustBeneficiaryProportionData(undefined))
  }
  
  return (
    <ModalEditScreen
      formTitle={'Adjust Proportions'}
      onDismiss={() => handleDismissScreen()}
      isDirty={isDirty}
      dismissDialogText={'Discard changes to beneficiary proportions?'}
      error={error}
      errorTryAgain={handleSubmit(onSubmit)}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={['Saving beneficiaries...']}
      buttonTitle={'Save Proportions'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
      allowTextButton={true}
      textButtonAction={equalizeValues}
      textButtonTitle={'Equalise Proportions'}
    >
      <View style={{ paddingBottom: Sizing.x20 }}>
        <Headline>{`Decide how much each will get`}</Headline>
      </View>
      <ManagedProportionInput
        formObj={formObj}
        name={'proportions'}
        labels={labels}
        minValue={0.01}
        maxValue={1}
        informationMessage={'Overall total should be 100%'}
      />
    </ModalEditScreen>
  )
}

