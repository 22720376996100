import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ProcessHeaderTitle } from 'components/Typography/ProcessHeaderTitle'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React, { useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import { Flex, Sizing, Paper } from 'styles'
import { layoutStyles } from 'styles/common'

export type ModalEditHeaderProps = {
  formTitle: string
  onDismiss: any
  isDirty?: boolean
  dismissDialogText?: string
  onDelete?: any
  deleteDialogText?: string
}

export const ModalEditHeader = (props: ModalEditHeaderProps) => {
  const { formTitle, onDismiss, dismissDialogText, onDelete, deleteDialogText, isDirty } = props
  
  const { colors: themeColors } = Paper.useAppTheme()

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const showDeleteDialog = () => setDeleteDialogVisible(true)
  const hideDeleteDialog = () => setDeleteDialogVisible(false)

  const [dismissDialogVisible, setDismissDialogVisible] = useState(false)
  const showDismissDialog = () => setDismissDialogVisible(true)
  const hideDismissDialog = () => setDismissDialogVisible(false)

  const showDelete = !!onDelete

  const handleDelete = () => {
    hideDeleteDialog()
    onDelete()
  }

  const handleDismiss = () => {
    hideDismissDialog()
    Keyboard.dismiss()
    onDismiss()
  }

  return (
    <>
      <View style={layoutStyles.modalHeaderContainer}>
        <View style={localStyles.headerRow}>
          <View style={localStyles.headerIconContainer}>
            { showDelete ?
              <MaterialCommunityIcons name="delete-outline" size={Sizing.x30} color={themeColors.primary} onPress={showDeleteDialog} />
              : <></>
            }
          </View>
          <View style={localStyles.headerTitleContainer}>
            <ProcessHeaderTitle>{formTitle}</ProcessHeaderTitle>
          </View>
          <View style={localStyles.headerIconContainer}>
            <MaterialCommunityIcons name="close" size={Sizing.x30} color={themeColors.primary} onPress={isDirty ? showDismissDialog : handleDismiss} />
          </View>
        </View>
      </View>
      <ConfirmationDialog
        visible={deleteDialogVisible}
        title={'Are you sure?'}
        content={deleteDialogText || 'Really delete this item?'}
        onCancel={hideDeleteDialog}
        onConfirm={handleDelete}
      />
      <ConfirmationDialog
        visible={dismissDialogVisible}
        title={'Are you sure?'}
        content={dismissDialogText || 'Close without saving?'}
        onCancel={hideDismissDialog}
        onConfirm={handleDismiss}
      />
    </>
  )
}

const localStyles = StyleSheet.create({
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
  },
  headerIconContainer: {
    ...Flex.column.center,
    width: Sizing.x50,
    alignItems: 'center'
  },
  headerTitleContainer: {
    ...Flex.column.center,
    flex: 1,
    paddingHorizontal: Sizing.x20,
  },
})