import { ModalProcessScreen } from 'components/Layout'
import { Text } from 'components/Typography/Text'
import { MoneyHubInfo } from 'components/Utility/MoneyHubInfo'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { openURL, parse, useURL } from 'expo-linking'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { captureException } from 'platform-lib/sentry'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import 'react-native-get-random-values'; //this must always be before importing uuid
import { useAddSinglePaymentContributionAuthMutation, useGetBankConnectionsQuery, useGetStatusQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingLumpSumContributionPayment, workingContributionSetup, workingLumpSumContributionPayment } from 'store/tempDataSlice'
import { setSnackbarData } from 'store/uxSlice'
import { Sizing } from 'styles'
import { v4 as uuid } from 'uuid'
import { BankLink } from '../Components/BankLink'
import { MoneyHubBankConnectionPaymentType } from 'store/dto/bank-connection.dto'
import { Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'

export const MakeContributions_14_LumpSumConfirm = ({ route, navigation }) => {
  const dispatch = useAppDispatch()
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}

  const [state, setState] = useState(uuid())
  const [nonce, setNonce] = useState(uuid())

  const [paymentAuthError, setPaymentAuthError] = useState(false)
  const [processing, setProcessing] = useState(false)

  const { data: status, isLoading: statusIsLoading, error: statusError, refetch: refetchStatus } = useGetStatusQuery()
  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()

  const [addSinglePaymentContribution, { data: singlePaymentData, isLoading: singlePaymentLoading, error: singlePaymentError }] = useAddSinglePaymentContributionAuthMutation()

  const isLoading = singlePaymentLoading || statusIsLoading || banksIsLoading || processing
  const error: any = singlePaymentError || statusError || banksError

  const workingLumpSumContributionPaymentData = useAppSelector(workingLumpSumContributionPayment)
  const isWeb = platformIsWeb()
  const url = isWeb ? undefined : useURL()

  //Go to open banking when we get the authUrl
  useEffect(() => {
    if (singlePaymentData?.authUrl) {
      setProcessing(true)
      openURL(singlePaymentData?.authUrl)
    }
  }, [singlePaymentData])

  //Get the authUrl when user hits proceed
  const doAuthPayment = async () => {
    addSinglePaymentContribution({
      amount: workingLumpSumContributionPaymentData?.amount,
      state,
      nonce,
    })
    //Needed for useEffect below - refactor needed
    dispatch(updateWorkingLumpSumContributionPayment({
      paymentToExecute: {
        state,
      }
    }))
  }

  const doManualPayment = async () => {
    dispatch(updateWorkingLumpSumContributionPayment({
      manualSetup: true,
      paymentToExecute: undefined,
    }))
    navigation.navigate(nextScreen)
  }

  //Handle the URL on return from open banking
  //Update the working payment data and go to next screen
  useEffect(() => {
    if (url && workingLumpSumContributionPaymentData?.paymentToExecute) {
      const processedUrl = url.replace('#', '?')
      const parsedUrl = parse(processedUrl)
      if (
        workingLumpSumContributionPaymentData?.paymentToExecute?.state !== parsedUrl?.queryParams?.state ||
        !parsedUrl?.queryParams?.code ||
        !parsedUrl?.queryParams?.id_token
        ) {
          setPaymentAuthError(true)
          dispatch(setSnackbarData({
            message: `There was an error authorizing the payment`,
            iconName: `alert`,
            duration: 5000
          }))
          setProcessing(false)
          captureException(new Error('Failed single payment attempt'), {
            extra: {
              singlePaymentData,
              parsedUrl,
              contributionsData,
            },
          })
      } else {
        setPaymentAuthError(false)
        dispatch(updateWorkingLumpSumContributionPayment({
          amount: +workingLumpSumContributionPaymentData?.amount,
          manualSetup: false,
          paymentToExecute: {
            code: parsedUrl?.queryParams?.code,
            state: parsedUrl?.queryParams?.state,
            nonce,
            id_token: parsedUrl?.queryParams?.id_token,
          },
        }))
        navigation.navigate('LumpSumSave')
      }
    }
  }, [url])

  const workingContributionData = useAppSelector(workingContributionSetup)
  const contributionConfiguration = contributionsData?.existingContributionConfiguration || workingContributionData

  const contributionBank = banks ? banks.find(bank => {
    return bank.id === contributionConfiguration?.bankConnectionId
  }) : undefined

  const useOpenBanking = contributionBank?.moneyHubId && contributionBank?.paymentTypes?.includes(MoneyHubBankConnectionPaymentType.DOMESTIC)

  const paymentData: UnborderedTableRow[] = [
    {
      label: `Amount`,
      value: formatCurrencyAmount(workingLumpSumContributionPaymentData?.amount, 2),
    },
    {
      label: `Reference`,
      value: contributionConfiguration?.paymentReference,
    },
    {
      label: `Payment Date`,
      value: format(new Date(), 'do MMMM'),
    },
  ]


  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={banksError ? refetchBanks : statusError ? refetchStatus : doAuthPayment}
      errorCancel={onDismiss}
      isLoading={isLoading}
      loadingMessage={singlePaymentLoading ? ['Connecting to your bank...'] : processing ? ['Waiting for authorisation...'] : undefined}
      buttonTitle={useOpenBanking ? paymentAuthError ? 'Try Again' : 'Proceed' : 'Next'}
      buttonAction={useOpenBanking ? doAuthPayment : doManualPayment}
      allowTextButton={useOpenBanking && (paymentAuthError || status?.testMode)}
      textButtonAction={doManualPayment}
      textButtonTitle={`Setup Manually Instead`}
      enableButton={!singlePaymentLoading}
      headline={useOpenBanking
        ? `Ok, we're ready to set that up for you`
        : `Ok, we need to do this manually`
      }
      
      subHeading={useOpenBanking
        ? `We'll connect to ${contributionBank?.name || 'your bank'} to authorise the contribution`
        : `Unfortunately ${contributionBank?.name || 'your bank'} doesn't support authorisation via Open Banking`
      }
      footerInfo={useOpenBanking ? <MoneyHubInfo /> : undefined}
      showButton={true}
    >
      {
        contributionBank ? <BankLink bank={contributionBank} isManual={!useOpenBanking} isError={paymentAuthError} /> : <></>
      }
      {
        useOpenBanking ?
        <View style={{ paddingVertical: Sizing.x10 }}>
          <UnborderedTable
            itemContainerStyle={{
              paddingVertical: Sizing.x5,
            }}
            data={paymentData?.map(item => {
              return {
                ...item,
                value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
              }
            })}
          />
        </View>
        : <>
            <Subheading style={{textAlign: 'left'}}>{`But don't worry...`}</Subheading>
            <BulletItem style={{textAlign: 'left'}}>{`This just means that you'll need to use your banking app/website to make the contribution.`}</BulletItem>
            <BulletItem style={{textAlign: 'left'}}>{`On the next screen, we'll provide you with all the details to create the paymen manually with ${contributionBank?.name || 'your bank'}.`}</BulletItem>
            <BulletItem style={{textAlign: 'left'}}>{`Please ensure the payment details exactly match.`}</BulletItem>
          </>
      }
      
    </ModalProcessScreen>
  )
}
