import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetBankConnectionQuery, useUpdateBankConnectionMutation } from 'store/apiSlice'
import { MoneyHubBankConnectionPaymentType, UpdateBankConnectionDto } from 'store/dto/bank-connection.dto'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminBankConnectionDataId, setCurrentAdminBankConnectionDataId } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { compact } from 'lodash'

export const AdminEditBankConnectionsModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const currentBankConnection = useAppSelector(currentAdminBankConnectionDataId)

  const currentBankConnectionId = useAppSelector(currentAdminBankConnectionDataId)

  const { data: bank, isLoading: bankIsLoading, error: bankError } = useGetBankConnectionQuery(currentBankConnectionId, { skip: !currentBankConnectionId } )
  const [updateBankConnection, { data: updatedBankConnection, isLoading: bankUpdateIsLoading, error: bankUpdateError }] = useUpdateBankConnectionMutation()

  const formObj = useForm<UpdateBankConnectionDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, register, setValue, reset, setError, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger('logo')
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')

  useEffect(() => {
    if (bank) {
      reset({
        name: bank?.name,
        description: bank?.description,
        logo: bank?.logo,
        contributionSources: bank?.contributionSources || [],
        paymentTypes: bank?.paymentTypes || [],
        isBeta: bank?.isBeta,
      })
    }
  }, [bank])

  //Form refs for focussing
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)

  const onSubmit = async attributes => {
    await updateBankConnection({ id: bank.id, ...attributes })
  }

  useEffect(() => {
    if (updatedBankConnection) {
      close()
    }
  }, [updatedBankConnection])

  const close = () => {
    dispatch(setCurrentAdminBankConnectionDataId(undefined))
  }

  const isLoading = bankIsLoading ||  bankUpdateIsLoading
  const error: any = bankError || bankUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const isBetaOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'No',
    },
    {
      value: true,
      label: 'Yes',
    },
  ]

  return (
    <ModalEditScreen
      formTitle={'Edit Bank Connection Details'}
      onDismiss={() => dispatch(setCurrentAdminBankConnectionDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this bank connection?'}
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={bankUpdateIsLoading ? ['Saving bank...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Identifier`,
            value: bank?.id,
            copyableValue: true,
          },
          {
            label: `Open Banking Enabled?`,
            value: !!bank?.moneyHubId ? 'Yes' : 'No',
          },
          bank?.moneyHubId ? {
            label: `MoneyHub Identifier`,
            value: bank?.moneyHubId || '(None)',
            copyableValue: true,
          } : undefined,
        ])}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => descriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={descriptionRef}
        name={'description'}
        formObj={formObj}
        label={'Description'}
        placeholder={'Optional description shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: false,
          minLength: 2,
          maxLength: 100,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Paragraph>{'Supported Contribution Sources'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'contributionSources'}
        options={enumToAutocompleteOptions(ContributionSource)}
      />
      {
        bank?.moneyHubId
          ? <>
              <Paragraph>{'Supported Open Banking Payment Types'}</Paragraph>
              <ManagedMultipleChoiceInput
                formObj={formObj}
                name={'paymentTypes'}
                options={enumToAutocompleteOptions(MoneyHubBankConnectionPaymentType)}
              />
              <Subheading>{'Beta Status (not visible to clients)'}</Subheading>
              <ManagedSimpleChoiceInput
                name={'isBeta'}
                formObj={formObj}
                options={isBetaOptions}
                required={true}
              />
            </>
            : <></>
      }
    </ModalEditScreen>
  )
}

