import { ModalProcessScreen } from 'components/Layout'
import { BulletItem } from 'components/Typography/BulletItem'
import { Text } from 'components/Typography/Text'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { addMonths, format, getDate, setDate } from 'date-fns'
import { formatOrdinalDay } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import { round } from 'lodash'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { useGetBankConnectionsQuery, useUpdateMeMutation, useUpdateAccountMutation } from 'store/apiSlice'
import { useAppSelector } from 'store/hooks'
import { workingContributionPayment, workingContributionSetup } from 'store/tempDataSlice'
import { Sizing } from 'styles'
import { BankLink } from '../Components/BankLink'
import { MakeContributionsData } from 'store/uxSlice'
import { JAR_NAME_ALL } from 'lib/constants'

export const MakeContributions_29_RegularSave = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss }: { nextScreen: string, contributionsData: MakeContributionsData, onDismiss: any } = route?.params || {}
  const { existingRegularContribution } = contributionsData || {}

  const workingContributionPaymentData = useAppSelector(workingContributionPayment)

  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()

  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()
  const [updateAccount, { data: updatedAccount, error: updatedAccountError, isLoading: updatedAccountIsLoading }] = useUpdateAccountMutation()
  
  const isLoading = banksIsLoading || updatedAccountIsLoading || clientUpdateIsLoading
  const error: any = banksError || updatedAccountError || clientUpdateError

  const regularContribution = updatedAccount?.regularContribution
  const today = new Date()
  const thisMonthDate = regularContribution?.dayOfMonth ? setDate(today, regularContribution?.dayOfMonth) : today
  const firstPaymentDate = regularContribution?.dayOfMonth < getDate(today) ? addMonths(thisMonthDate, 1) : thisMonthDate

  const manualSetup = workingContributionPaymentData?.manualSetup

  // Save on get banks
  useEffect(() => {
    if (banks) {
      saveAccount()
    }
  }, [banks])

  //Save client when contribution saved
  useEffect(() => {
    if (updatedAccount) {
      ampli.contributionChangeRegular({
        amount: updatedAccount?.regularContribution?.amount,
        dayOfMonth: updatedAccount?.regularContribution?.dayOfMonth,
      })
      saveClient()
    }
  }, [updatedAccount])

  const saveAccount = () => {
    Logger.info(`Updating pension regular contributions...`)
    updateAccount({
      id: contributionsData?.account?.id,
      regularContribution: workingContributionPaymentData === null ? null : {
        ...workingContributionPaymentData,
        amount: round(workingContributionPaymentData?.amount || 0, 2),
        dayOfMonth: getDate(new Date(workingContributionPaymentData.firstPaymentDate))
      }
    })
  }

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        contribute: true,
      },
    })
  }

  //Clear data and either close or next step
  const next = () => {
    navigation.navigate(nextScreen)
  }

  const workingContributionData = useAppSelector(workingContributionSetup)
  const contributionConfiguration = contributionsData?.existingContributionConfiguration || workingContributionData
  
  const contributionBank = banks ? banks.find(bank => {
    return bank.id === contributionConfiguration?.bankConnectionId
  }) : undefined

  const standingOrderData: UnborderedTableRow[] = [
    {
      label: `Monthly Amount`,
      value: formatCurrencyAmount(regularContribution?.amount, 2),
    },
    {
      label: `Reference`,
      value: contributionConfiguration?.paymentReference,
    },
    {
      label: `First Payment`,
      value: firstPaymentDate ? format(new Date(firstPaymentDate), 'do MMMM') : '',
    },
    {
      label: `Future Payments`,
      value: firstPaymentDate ? `On the ${formatOrdinalDay(new Date(firstPaymentDate))}` : '',
    },
  ]

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Finalizing regular contributions...']}
      error={error}
      errorTryAgain={banksError ? refetchBanks : updatedAccount ? saveClient : saveAccount}
      errorCancel={onDismiss}
      buttonTitle={'Next'}
      buttonAction={next}
      showButton={true}
      headline={`Ok, your regular contribution is setup`}
      subHeading={`Contributions will be expected according to the schedule below`}
    >
      {
        contributionBank ? <BankLink bank={contributionBank} isComplete={true} /> : <></>
      }
      <View style={{ paddingVertical: Sizing.x10 }}>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          data={standingOrderData?.map(item => {
            return {
              ...item,
              value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
            }
          })}
        />
      </View>
      <BulletItem style={{textAlign: 'left'}}>
        {`Please do not amend or cancel the standing order from your bank without following the process in the Jarvis app. `}
      </BulletItem>
      {
        existingRegularContribution
          ? <BulletItem style={{textAlign: 'left'}}>{`Please ensure you have cancelled any previous standing orders from your bank to your ${JAR_NAME_ALL}.`}</BulletItem>
          : <></>
      }
    </ModalProcessScreen>
  )
}
